import React, { useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';

import arrow from 'images/svg/arrow.svg';
import cross from 'images/svg/cross.svg';

import timeStampToObject from 'parsers/timeStampToObject';

const defaultState = {
    uploadingExt: false,
    deleteUploadState: false,
    uploadingName: false,
};

const File = ({
    file,
    editMode,
    editable,
    setUploadArray,
    directSave,
    progress,
}) => {
    const fileInput = useRef(null);
    const [
        { uploadingName, uploadingExt, deleteUploadState },
        setUploadingState,
    ] = useState(defaultState);
    const time = timeStampToObject(file.timestamp);

    useEffect(() => {
        setUploadingState(defaultState);
    }, [editMode]);

    const progressStyles = {
        backgroundPosition:
            progress === 100 ? `-100% bottom` : `${100 - progress}% bottom`,
    };

    const uploadFile = (e, name, trigger = false) => {
        const uploadedFile = e.target.files[0];

        if (uploadedFile) {
            const extension = uploadedFile.name.substring(
                uploadedFile.name.lastIndexOf('.') + 1
            );
            const fileName = uploadedFile.name.substring(
                0,
                uploadedFile.name.length - extension.length
            );

            let shortName = fileName;

            if (fileName.length > 10) {
                shortName = `${fileName.substr(0, 10)}...`;
            }

            setUploadingState({
                deleteUploadState: false,
                uploadingExt: extension,
                uploadingName: shortName,
            });

            if (!trigger) {
                setUploadArray({ name, uploadedFile });
            } else {
                directSave({ name, uploadedFile });
            }
        }
    };

    const removeFile = () => {
        setUploadingState({
            deleteUploadState: true,
            uploadingExt: false,
        });
        setUploadArray({ name: file.name, uploadedFile: 'delete' });
    };

    const handleEnterKey = (e) => {
        if ('Enter' === e.keyCode) {
            e.target.click();
        }
    };

    const docTypes =
        '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf';

    const fileUpload = (
        <div
            className="file-wrapper"
            style={progressStyles}
            progress={progress}
        >
            <input
                type="file"
                accept={docTypes}
                ref={fileInput}
                onChange={(e) => uploadFile(e, file.name)}
            />
            <div
                className="file-name bold"
                onClick={() => fileInput.current.click()}
            >
                <span>Last opp {file.name.toLowerCase()}</span>
            </div>
            <button
                className="add-wrapper"
                onClick={() => fileInput.current.click()}
                onKeyPress={(e) => handleEnterKey(e)}
            >
                <img src={cross} alt="Legg til fil" />
            </button>
        </div>
    );

    const fileOverwrite = (
        <div
            className="file-wrapper"
            style={progressStyles}
            progress={file.ext ? '100' : '0'}
        >
            <div className="file-name">
                {!uploadingExt && file.ext && (
                    <>
                        <span>{`${time.day}. ${time.monthString}`}</span>
                        <span className="bold">{file.name}</span>
                        <span>({file.filename_short})</span>
                    </>
                )}
                {uploadingExt && (
                    <>
                        <span>({uploadingName})</span>
                    </>
                )}
            </div>
            <button className="remove-wrapper">
                <img
                    onClick={() => removeFile(file.name)}
                    onKeyPress={(e) => handleEnterKey(e)}
                    src={cross}
                    alt="Fjern fil"
                />
            </button>
        </div>
    );

    const fileDownload = (
        <div
            className={`file-wrapper ${!deleteUploadState ? 'animate' : ''}`}
            style={progressStyles}
            progress={progress}
        >
            <div className="file-name">
                <a href={file.url} download>
                    <span>{`${time.day}. ${time.monthString}`}</span>
                    <span className="bold">{file.name}</span>
                    <span>({file.filename_short})</span>
                </a>
            </div>
            <div className="arrow-wrapper">
                <a
                    href={file.url}
                    aria-label={'Download ' + file.name}
                    download
                >
                    {' '}
                    <img src={arrow} alt="Last ned fil" />
                </a>
            </div>
        </div>
    );

    const fileTriggerEdit = (
        <div className="file-wrapper" style={progressStyles}>
            <input
                type="file"
                accept={docTypes}
                ref={fileInput}
                onChange={(e) => uploadFile(e, file.name, true)}
            />
            <div
                className="file-name bold"
                onClick={() => {
                    fileInput.current.click();
                }}
            >
                <span>Last opp {file.name.toLowerCase()}</span>
            </div>
            <button
                className="add-wrapper"
                onClick={() => {
                    fileInput.current.click();
                }}
                onKeyPress={(e) => handleEnterKey(e)}
            >
                <div>
                    <img src={cross} alt="Legg til fil" />
                </div>
            </button>
        </div>
    );

    if (!editable) {
        if (file.ext) {
            return fileDownload;
        }

        return null;
    }

    if (editMode && ((!file.ext && !uploadingExt) || deleteUploadState)) {
        return fileUpload;
    }
    if (editMode && (file.ext || uploadingExt)) {
        return fileOverwrite;
    }
    if (!editMode && !file.ext) {
        return fileTriggerEdit;
    }
    if (!editMode && file.ext) {
        return fileDownload;
    }
};

File.defaultProps = {
    editable: false,
    className: '',
};

File.propTypes = {
    className: PropTypes.string,

    editable: PropTypes.bool,
};

export default File;
