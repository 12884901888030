import React from 'react';
import * as PropTypes from 'prop-types';

const UserCard = ({ name, title, actor, face }) => {
    return (
        <div className="user-card">
            <div className="user-card-info">
                <h3>{name}</h3>
                <span>{title}</span>
                <a href="din-virksomhet">{actor.title}</a>
            </div>
            <div className="user-card-image">
                <div>{face && <img alt={name} src={face} />}</div>
            </div>
        </div>
    );
};

UserCard.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    actor: PropTypes.object.isRequired,
    face: PropTypes.string.isRequired,
};

export default UserCard;
