import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import ContactCard from 'components/ContactCard';
import UserCard from 'components/UserCard';

import useForum from 'hooks/useForum';

import ActorSlider from 'pages/FrontPageUser/actorSlider';
import CourseList from 'pages/FrontPageUser/courseList';
import DialogLatest from 'pages/FrontPageUser/dialogLatest';
import GreetingToast from 'pages/FrontPageUser/greetingToast';
import Tools from 'pages/FrontPageUser/tools';

const LandingPage = (props) => {
    useForum();
    const { user, options, events, entries } = props;
    const [toastState, setToastState] = useState(true);

    return (
        <div className="page-front-user-wrapper">
            {!!toastState && user.toast !== false && (
                <GreetingToast
                    name={user.name}
                    promoter={user.actor.promoter}
                    setToastState={setToastState}
                />
            )}
            <div className="double-card">
                <div className="user-wrapper">
                    <UserCard
                        name={user.name}
                        title={user.acf.title}
                        actor={user.actor}
                        face={user.image}
                    />
                    <ActorSlider
                        number={user.actor.innovation_progress}
                        numberMax={6}
                        actor={user.actor.title}
                        actorSlug="din-virksomhet"
                    />
                </div>
                <div className="dialog-wrapper">
                    <DialogLatest entries={entries} />
                </div>
            </div>
            {options.tools && (
                <div className="tools-wrapper">
                    <h2>Verktøy</h2>
                    <Tools
                        email={options.suggest_tools_email}
                        subject={options.suggest_tools_subject}
                        tools={[...options.tools]}
                    />
                </div>
            )}
            {events && <CourseList options={options} events={[...events]} />}

            <div className="contact-card-wrapper">
                <h2>Kontaktperson</h2>
                <ContactCard
                    name={user.actor.promoter.name}
                    title={user.actor.promoter.title}
                    email={user.actor.promoter.email}
                    phone={user.actor.promoter.phone}
                    imageSrc={user.actor.promoter.image}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...state.user,
        ...state.global,
        ...state.forum,
        ...ownProps,
    };
};

LandingPage.propTypes = {
    user: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
    events: PropTypes.array.isRequired,
    entries: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(LandingPage);
