import React from 'react';
import * as PropTypes from 'prop-types';

import StepProgressBar from 'components/StepProgressBar';

import arrow from 'images/svg/arrow';

const ActorSlider = ({ actor, actorSlug, number, numberMax }) => {
    return (
        <div className="actor-slider">
            <div className="top">
                <a href={actorSlug}>
                    <h2>{actor}</h2>
                </a>
                <a href={actorSlug} title={actor}>{arrow()}</a>
            </div>
            <StepProgressBar currentNumber={number} maxNumbers={numberMax} />
            <p>
                Din virksomhet har gjennomført {number} av {numberMax} tiltak
                for en mer innovasjonsvennlig anskaffelsespraksis.
            </p>
            <a href={actorSlug}>Gå til virksomhet</a>
        </div>
    );
};

ActorSlider.propTypes = {
    actor: PropTypes.string.isRequired,
    actorSlug: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
    numberMax: PropTypes.number.isRequired,
};

export default ActorSlider;
