import { combineReducers } from 'redux';

import { UserReducer } from 'reducers/userReducer';
import { ActorReducer } from 'reducers/actorReducer';
import { GlobalReducer } from 'reducers/globalReducer';
import { ForumReducer } from 'reducers/forumReducer';
import { ProcurementReducer } from 'reducers/procurementReducer';

export default combineReducers({
    user: UserReducer,
    actors: ActorReducer,
    procurements: ProcurementReducer,
    global: GlobalReducer,
    forum: ForumReducer,
});
