import React, { useState } from 'react';

import ApiManager from 'ApiManager';

import GoBack from 'components/GoBack';

import whiteArrow from 'images/svg/whiteArrow';

import CourseInfo from 'pages/SingleCoursePage/courseInfo';
import CourseFiles from 'pages/SingleCoursePage/courseFiles';
import CourseSpeakers from 'pages/SingleCoursePage/courseSpeakers';
import CourseText from 'pages/SingleCoursePage/courseText';

const SingleCoursePage = (props) => {
    const { authToken, match, user } = props;
    const { slug } = match.params;
    const [courseData, setCourseData] = useState(false);

    const getCourse = () => {
        const Api = new ApiManager();
        Api.getSingleCourse(slug).then((response) => {
            setCourseData(...response.data);
        });
    };

    if (!courseData) {
        getCourse();
        return (
            <div className="page-single-course-wrapper">
                <GoBack text="Til kursoversikt" />
            </div>
        );
    }

    return (
        <>
            <div className="page-single-course-wrapper">
                <CourseInfo data={courseData} />
                {courseData.signup_link && (
                    <a
                        href={courseData.signup_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn primary"
                    >
                        <span>Meld deg på her</span>{' '}
                        <span>{whiteArrow()}</span>
                    </a>
                )}
                <CourseText data={courseData} />
                {courseData.documents.length > 0 && (
                    <CourseFiles files={courseData.documents} />
                )}
                {courseData.speakers.length > 0 && (
                    <CourseSpeakers data={courseData} />
                )}

                {courseData.signup_link && (
                    <a
                        href={courseData.signup_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn primary"
                    >
                        <span>Meld deg på her</span>{' '}
                        <span>{whiteArrow()}</span>
                    </a>
                )}
            </div>
        </>
    );
};

export default SingleCoursePage;
