import * as React from 'react';

function arrow(props) {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <svg width={19} height={20} viewBox="0 0 19 20" fill="none" {...props}>
            <path
                d="M17.95 10.6c-.02.03-.05.06-.08.08l-.01.01c.03-.02.06-.06.09-.09zm.23-1.59a1.424 1.424 0 000 0zm-.32-.45c.04.04.07.07.1.11-.03-.04-.06-.08-.1-.11zm-4.67-.43H1.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h11.69l1.5-1.5-1.5-1.5z"
                fill="#CCC"
            />
            <path
                d="M8.69 0c-.39 0-.77.15-1.06.44a1.49 1.49 0 000 2.12l5.56 5.56h3.62c.22 0 .43.05.61.13.12.06.24.13.34.22.03.03.06.05.09.08L9.75.44C9.45.15 9.07 0 8.69 0z"
                fill="#7C17FF"
            />
            <path
                d="M17.78 8.48c.03.03.07.06.1.09l-.01-.01a.647.647 0 00-.09-.08zm-.97-.35h-3.62l1.5 1.5 1.06-1.06c.29-.3.68-.44 1.06-.44z"
                fill="#6312CC"
            />
            <path
                d="M17.86 10.69a1.587 1.587 0 01-.43.29c-.19.08-.39.13-.61.13H13.2l-5.56 5.56a1.49 1.49 0 000 2.12c.29.29.68.44 1.06.44s.77-.15 1.06-.44l8.1-8.1z"
                fill="#939598"
            />
            <path
                d="M17.87 10.69zm-3.18-1.06l-1.5 1.5h3.62c-.38 0-.77-.15-1.06-.44l-1.06-1.06z"
                fill="#76777A"
            />
            <path
                d="M17.78 10.77zm.41-.54c-.06.14-.14.26-.23.37.09-.12.17-.24.23-.37zm-.23-1.57zm-.53-.4c.12.06.24.13.34.22-.1-.09-.22-.17-.34-.22z"
                fill="#470D98"
            />
            <path
                d="M16.81 8.13c-.38 0-.77.15-1.06.44l-1.06 1.06 1.06 1.06c.29.29.68.44 1.06.44.21 0 .42-.04.61-.13.13-.06.24-.13.35-.22.03-.03.06-.05.09-.08s.05-.06.08-.08c.09-.11.17-.24.23-.37a1.53 1.53 0 00-.01-1.21c-.06-.12-.13-.24-.22-.34-.03-.03-.06-.07-.09-.1s-.06-.06-.1-.09c-.1-.09-.22-.16-.34-.22-.18-.12-.39-.16-.6-.16z"
                fill="#390B7A"
            />
        </svg>
    );
}

export default arrow;
