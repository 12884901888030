import axios from 'axios';
import Store from 'store';

export default class ApiManager {
    constructor() {
        this.url = process.env.REACT_APP_API_URL;

        this.state = {
            authToken: null,
        };
    }

    /**
     * Returns the headers needed for a request.
     * @returns {{Accept: string, "Content-Type": string}}
     */
    getHeaders = (token = false) => {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };

        const { user } = Store.getState();

        if (token) {
            headers.Authorization = `Bearer ${token}`;
        } else if (user.authToken) {
            headers.Authorization = `Bearer ${user.authToken}`;
        } else if (this.state.authToken) {
            headers.Authorization = `Bearer ${this.state.authToken}`;
        }

        return headers;
    };

    /**
     * Sets the authToken manually.
     * @param authToken
     */
    setAuthToken = (authToken) => {
        this.state.authToken = authToken;
    };

    /**
     * generic GET request to the API
     * @param  {String} path
     * @param token
     * @return {Promise}
     */
    get(path, token = false) {
        return axios.get(this.url + path, {
            headers: this.getHeaders(token),
        });
    }

    /**
     * generic POST request to the API
     * @param  {String} path
     * @param  {Object} data
     * @param config
     * @return {Promise}
     */
    post(path, data, config = {}) {
        return axios.post(this.url + path, data, {
            ...config,
            headers: this.getHeaders(),
        });
    }

    /**
     * generic PUT request to the API
     * NOTE: PUT does not work with FormData() use POST instead and append '_method', 'PUT' to form data
     * @param  {String} path
     * @param  {Object} data
     * @return {Promise}
     */
    put(path, data) {
        return axios.put(this.url + path, data, {
            headers: this.getHeaders(),
        });
    }

    /**
     * generic DELETE request to the API
     * @param  {String} path
     * @return {Promise}
     */
    delete(path) {
        return axios.delete(this.url + path, {
            headers: this.getHeaders(),
        });
    }

    /**
     * Sends a login request.
     * @param username
     * @param password
     * @returns {Promise}
     */
    loginUser = (username, password) => {
        return this.post('/jwt-auth/v1/token', {
            username,
            password,
        });
    };

    /**
     * Sends a login request.
     * @param username
     * @param password
     * @param hash
     * @returns {Promise}
     */
    resetUserByEmail = (username, password, hash) => {
        return this.post('/user/v1/alt-reset', {
            username,
            password,
            hash,
        });
    };

    /**
     * Sends a change password request.
     * @param password
     * @returns {Promise}
     */
    changePassword = (password) => {
        return this.post('/user/v1/change-password', {
            password,
        });
    };

    /**
     * Sends a change password request.
     * @param name
     * @param title
     * @param editPassword
     * @param password
     * @param forumState
     * @returns {Promise}
     */
    editCurrentUser = (
        name,
        title,
        editPassword,
        password,
        forumState,
        phoneState
    ) => {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('title', title);
        formData.append('editPassword', editPassword);
        formData.append('password', password);
        formData.append('forum', forumState);
        formData.append('phone', phoneState);
        return this.post('/user/v1/edit-current-user', formData);
    };

    /**
     * Sends a change password request.

     * @param image
     * @returns {Promise}
     */
    editCurrentUserImage = (image) => {
        const formData = new FormData();
        formData.append('file', image);
        return this.post('/user/v1/edit-current-user', formData);
    };

    /**
     * Sends a reset password request.
     * @param email
     * @returns {Promise}
     */
    resetPassword = (email) => {
        return this.post('/user/v1/reset-password', {
            email,
        });
    };

    getGlobals = () => {
        return this.get('/globals/v1/get-globals');
    };

    setClosedToast = () => {
        return this.get('/user/v1/closed-toast');
    };

    addMember = (formData) => {
        return this.post('/user/v1/add-member', {
            name: formData.name,
            email: formData.email,
            title: formData.title,
        });
    };

    removeMember = (id) => {
        return this.get('/nho/v1/remove/' + id);
    };

    /**
     * Returns the current logged in user
     * @returns {Promise}
     */
    getLoggedInUser = (token = false) => {
        return this.get('/user/v1/user', token);
    };

    /**
     * Returns all procurements.
     * @returns {Promise}
     */
    getProcurements = (limit) => {
        const perPage = limit || 3;

        return this.get(`/wp/v2/posts?per_page=${perPage}`);
    };

    /**
     * @returns {Promise}
     * @param uploadedFileArray
     * @param config
     */
    editCurrentActorFile = (uploadedFileArray, config) => {
        const formData = new FormData();
        // eslint-disable-next-line array-callback-return
        uploadedFileArray.map((val, key) => {
            formData.append(`name${key}`, val.name);
            formData.append(`file${key}`, val.uploadedFile);
        });
        formData.append('length', uploadedFileArray.length);

        return this.post('/actor/v1/edit-current-actor-file', formData, config);
    };

    /**
     * @returns {Promise}
     * @param slug
     */
    getSingleCourse = (slug) => {
        return this.post('/course/v1/single-course', { slug });
    };

    /**
     * @returns {Promise}
     */
    getForum = () => {
        return this.get('/forum/v1/get-forum-data');
    };

    /**
     * Sends a change password request.
     * @returns {Promise}
     * @param data
     * @param tags
     */
    newForumEntry = (data, tags) => {
        const formData = new FormData();
        formData.append('title', data.title);
        formData.append('content', data.content);
        formData.append('category', JSON.stringify(tags));
        return this.post('/forum/v1/new-forum-entry', formData);
    };

    /**
     * Sends a change password request.
     * @returns {Promise}
     * @param data
     * @param parent
     */
    replyForumEntry = (data, parent) => {
        const formData = new FormData();
        formData.append('content', data.content);
        formData.append('parent', parent);
        return this.post('/forum/v1/reply-forum-entry', formData);
    };

    /**
     * @returns {Promise}
     * @param uploadedFileArray
     * @param entryId
     * @param parent
     * @param config
     */
    uploadForumFile = (uploadedFileArray, entryId, parent, config) => {
        const formData = new FormData();
        // eslint-disable-next-line array-callback-return
        uploadedFileArray.map((val, key) => {
            formData.append(`name${key}`, val.name);
            formData.append(`file${key}`, val.uploadedFile);
        });
        formData.append(`entry`, entryId);
        formData.append(`parent`, parent);
        formData.append('length', uploadedFileArray.length);

        return this.post('/forum/v1/upload-file', formData, config);
    };

    /**
     * @returns {Promise}
     */
    getActors = () => {
        return this.get('/actors/v1/get-all-actors');
    };

    /**
     * @returns {Promise}
     */
    getAllProcurements = () => {
        return this.get('/procurements/v1/get-all-procurements');
    };
}
