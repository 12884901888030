import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import ApiManager from 'ApiManager';

import Button from 'components/Button';

import artBoard from 'images/artboard-bottom.svg';
import artBoard2 from 'images/artboard-guy-mobile.png';
import UserIcon from 'images/user-icon.svg';

import UserActions from 'reducers/userReducer';

const validateParams = (email, password) => {
    return !!email && !!password;
};
const UrlLoginPage = (props) => {
    const { match, user } = props;
    const { email, hash } = match.params;
    const [loginState, setLoginState] = useState(false);
    const [passwordState, setPasswordState] = useState('');
    const [requesting, setRequesting] = useState(false);

    if (user.email && loginState !== true) {
        UserActions.logOutUser();
        //  window.location.reload();
    }

    const urlLogin = validateParams(email, hash);

    if (loginState || !urlLogin) {
        return <Redirect to="/" />;
    }

    const changePassword = () => {
        if (requesting === true) return null;
        if (passwordState.password !== passwordState.confirm) return null;
        if (passwordState.password === '') return null;

        setRequesting(true);
        const Api = new ApiManager();

        Api.resetUserByEmail(
            decodeURI(email),
            passwordState.password,
            encodeURI(hash)
        )
            .then(() => {
                Api.loginUser(decodeURI(email), passwordState.password).then(
                    (response) => {
                        const newToken = response.data.token;
                        Api.setAuthToken(newToken);
                        Api.getLoggedInUser(newToken)
                            .then((userResponse) => {
                                setLoginState(true);

                                UserActions.logInUser(
                                    newToken,
                                    userResponse.data
                                );
                            })
                            .catch((error) => {
                                console.log('ERROR [getLoggedInUser]', error);
                            });
                    }
                );
            })
            .catch((error) => {
                console.log('ERROR [loginUser]', error);
            })
            .finally(() => {
                // self.setState({ requesting: false });
            });

        return true;
    };

    return (
        <div className="page-url-login-wrapper">
            <div className="grey-background"></div>
            <div className="content-wrapper">
                <div className="image">
                    <img alt="art" src={artBoard} />
                    <img alt="art" src={artBoard2} />
                </div>
                <div className="url-login-wrapper">
                    <h3>
                        <img src={UserIcon} alt="" /> Du må endre passord før du
                        kan gå videre.
                    </h3>

                    <div className="url-login-fields">
                        <span>Passord</span>
                        <input
                            type="password"
                            onChange={(e) =>
                                setPasswordState({
                                    ...passwordState,
                                    password: e.target.value,
                                })
                            }
                        />
                        <span>Bekreft passord</span>
                        <input
                            type="password"
                            onChange={(e) =>
                                setPasswordState({
                                    ...passwordState,
                                    confirm: e.target.value,
                                })
                            }
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    changePassword();
                                }
                            }}
                        />
                    </div>

                    <Button primary onClick={changePassword}>
                        Endre passord
                    </Button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...state.user,
        ...ownProps,
    };
};

UrlLoginPage.propTypes = {
    authToken: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(UrlLoginPage);
