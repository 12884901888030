import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';

import ContactCard from 'components/ContactCard';

import useForum from 'hooks/useForum';

import CategoryFilter from 'pages/DialogPage/categoryFilter';
import DialogIntro from 'pages/DialogPage/dialogIntro';
import DialogLister from 'pages/DialogPage/dialogLister';
import DialogScroller from 'pages/DialogPage/dialogScroller';
import SearchBar from 'pages/DialogPage/searchBar';

const ForumPage = (props) => {
    useForum();

    const { entries, user } = props;
    const dialogListRef = useRef(null);
    const [searchState, setSearchState] = useState(null);
    const [categoryFilterState, setCategoryFilterState] = useState(false);

    const scrollToRef = () =>
        window.scrollTo(0, dialogListRef.current.offsetTop + 50);

    const starred =
        entries &&
        entries.filter((item) => {
            return item.starred === true;
        });

    const filteredEntries = entries.filter((item) => {
        if (!searchState) return true;
        if (item.author.name.toLowerCase().includes(searchState)) return true;
        if (item.content.toLowerCase().includes(searchState)) return true;
        if (item.title.toLowerCase().includes(searchState)) return true;

        return (
            item.categories.filter((cat) => {
                return cat.name.toLowerCase().includes(searchState);
            }).length > 0
        );
    });

    const sortedEntries = () => {
        let sorted = [];
        filteredEntries.map((entry) => {
            entry.categories.map((category) => {
                sorted = {
                    ...sorted,
                    [category.name]: {
                        ...sorted[category.name],
                        [entry.id]: entry,
                    },
                };
            });
        });
        return sorted;
    };
    return (
        <div className="page-dialog-wrapper">
            <DialogIntro />
            <div className="search-wrapper">
                <SearchBar
                    setSearchState={setSearchState}
                    searchState={searchState}
                />
                {filteredEntries && (
                    <CategoryFilter
                        setFilter={(val) => {
                            setCategoryFilterState(val);
                            scrollToRef();
                        }}
                        entries={sortedEntries()}
                    />
                )}
            </div>
            {starred && starred.length > 0 && (
                <div className="scroller-wrapper">
                    <h2>Fremhevet</h2>
                    <DialogScroller primary entries={starred} />
                </div>
            )}
            {filteredEntries && filteredEntries.length > 0 && (
                <>
                    <div ref={dialogListRef} />
                    <DialogLister
                        categoryFilter={categoryFilterState}
                        setFilter={(val) => {
                            setCategoryFilterState(val);
                            setSearchState(val);
                        }}
                        entries={filteredEntries}
                    />
                </>
            )}

            <div className="contact-card-wrapper">
                <h2>Kontaktperson</h2>
                <ContactCard
                    name={user.actor.promoter.name}
                    title={user.actor.promoter.title}
                    email={user.actor.promoter.email}
                    phone={user.actor.promoter.phone}
                    imageSrc={user.actor.promoter.image}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...state.user,
        ...state.forum,
        ...ownProps,
    };
};

export default connect(mapStateToProps)(ForumPage);
