import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import MultiPageNavigator from 'components/MultiPageNavigator';

import useProcurements from 'hooks/useProcurements';

import timeStampToObject from 'parsers/timeStampToObject';

import whiteArrow from 'images/svg/whiteArrow';

const Procedures = ({ procurements }) => {
    useProcurements();
    const [categoryFilterState, setCategoryFilterState] = useState([]);
    const [procedureFilterState, setProcedureFilterState] = useState([]);
    const [procedureListState, setProcedureListState] = useState(false);
    const [categoryListState, setCategoryListState] = useState(false);
    const [navCurrentState, setNavCurrentState] = useState(1);

    function useOutsideClicker(categoryFilterRef, procedureFilterRef) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (
                    categoryFilterRef.current &&
                    !categoryFilterRef.current.contains(event.target)
                ) {
                    setCategoryListState(false);
                }
                if (
                    procedureFilterRef.current &&
                    !procedureFilterRef.current.contains(event.target)
                ) {
                    setProcedureListState(false);
                }
            }

            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [categoryFilterRef, procedureFilterRef]);
    }

    const categoryFilterRef = useRef(null);
    const procedureFilterRef = useRef(null);
    useOutsideClicker(categoryFilterRef, procedureFilterRef);

    if (!procurements.procurements) return <div />;

    const filteredProcurements = procurements.procurements.filter((item) => {
        let procedureTest;
        if (procedureFilterState.length < 0) {
            procedureTest = true;
        } else {
            procedureTest =
                item.procedures &&
                item.procedures.filter((itemProc) => {
                    return (
                        procedureFilterState.filter((procFilter) => {
                            return procFilter.name === itemProc.name;
                        }).length > 0
                    );
                }).length === procedureFilterState.length;
        }
        let categoryTest;
        if (categoryFilterState.length < 0) {
            categoryTest = true;
        } else {
            categoryTest =
                item.categories &&
                item.categories.filter((itemCat) => {
                    return (
                        categoryFilterState.filter((catFilter) => {
                            return catFilter.name === itemCat.name;
                        }).length > 0
                    );
                }).length === categoryFilterState.length;
        }

        return categoryTest && procedureTest;
    });
    const formatDate = (dateString) => {
        const unix = new Date(dateString).getTime() / 1000;
        return timeStampToObject(unix);
    };

    const navPageLimit = 5;
    const navMax =
        (filteredProcurements.length +
            (navPageLimit - (filteredProcurements.length % navPageLimit))) /
        navPageLimit;

    return (
        <div className="procedures-wrapper">
            <div className="dropdown-wrapper">
                <div className="dropdown-list-wrapper">
                    <div>
                        <div
                            ref={procedureFilterRef}
                            onClick={(e) => {
                                setProcedureListState(true);
                                setProcedureFilterState([]);
                            }}
                            onBlur={() => setProcedureListState(false)}
                        >
                            {procedureFilterState.length > 0 ? (
                                procedureFilterState[0].name
                            ) : (
                                <>
                                    Velg prosedyre <span>{whiteArrow()}</span>
                                </>
                            )}
                        </div>
                    </div>
                    <ul className={`dropdown-list ${procedureListState ? 'open' : ''}`}>
                        {procurements.procedures
                            .filter((item) => {
                                if (
                                    procedureFilterState.filter(
                                        (filterItem) => {
                                            return (
                                                item.name === filterItem.name
                                            );
                                        }
                                    ).length > 0
                                ) {
                                    return false;
                                }
                                return (
                                    filteredProcurements.filter(
                                        (procurmentItem) => {
                                            return (
                                                procurmentItem.procedures.filter(
                                                    (procedure) => {
                                                        return (
                                                            procedure.name ===
                                                            item.name
                                                        );
                                                    }
                                                ).length > 0
                                            );
                                        }
                                    ).length > 0
                                );
                            })
                            .map((procedure) => {
                                return (
                                    <li
                                        key={procedure.slug}
                                        onClick={() => {
                                            setProcedureFilterState([
                                                //  ...procedureFilterState,
                                                { ...procedure },
                                            ]);
                                            setProcedureListState(false);
                                        }}
                                    >
                                        {procedure.name}
                                    </li>
                                );
                            })}
                    </ul>
                </div>
                {/* <DropdownListWrapper open={categoryListState}> */}
                <div className="dropdown-list-wrapper">
                    <div>
                        <div
                            ref={categoryFilterRef}
                            onClick={() => {
                                setCategoryListState(true);
                                setCategoryFilterState([]);
                            }}
                        >
                            {categoryFilterState.length > 0 ? (
                                categoryFilterState[0].name
                            ) : (
                                <>
                                    Velg kategori <span>{whiteArrow()}</span>
                                </>
                            )}
                        </div>
                    </div>
                    <ul className={`dropdown-list ${categoryListState ? 'open' : ''}`}>
                        {procurements.categories
                            .filter((item) => {
                                if (
                                    categoryFilterState.filter((filterItem) => {
                                        return item.name === filterItem.name;
                                    }).length > 0
                                ) {
                                    return false;
                                }
                                return (
                                    filteredProcurements.filter(
                                        (procurmentItem) => {
                                            return (
                                                procurmentItem.categories.filter(
                                                    (category) => {
                                                        return (
                                                            category.name ===
                                                            item.name
                                                        );
                                                    }
                                                ).length > 0
                                            );
                                        }
                                    ).length > 0
                                );
                            })
                            .map((category) => {
                                return (
                                    <li
                                        key={category.slug}
                                        onClick={() => {
                                            setCategoryFilterState([
                                                // ...categoryFilterState,
                                                { ...category },
                                            ]);
                                            setCategoryListState(false);
                                        }}
                                    >
                                        {category.name}
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            </div>
            {filteredProcurements && (
                <div className="selected-wrapper">
                    <h3>
                        {filteredProcurements.length}{' '}
                        <span>
                            {filteredProcurements.length === 1
                                ? 'anskaffelse'
                                : 'anskaffelser'}
                        </span>
                        {categoryFilterState.length > 0 && (
                            <>
                                <span>i sektoren </span>

                                {categoryFilterState.map((category) => {
                                    return (
                                        <div
                                            key={category.slug}
                                            onClick={() =>
                                                setCategoryFilterState(
                                                    categoryFilterState.filter(
                                                        (item) =>
                                                            item.name !==
                                                            category.name
                                                    )
                                                )
                                            }
                                        >
                                            {category.name}
                                        </div>
                                    );
                                })}
                            </>
                        )}
                        {procedureFilterState.length > 0 && (
                            <>
                                <span>utført med </span>

                                {procedureFilterState.map((procedure) => {
                                    return (
                                        <div
                                            key={procedure.slug}
                                            onClick={() =>
                                                setProcedureFilterState(
                                                    procedureFilterState.filter(
                                                        (item) =>
                                                            item.name !==
                                                            procedure.name
                                                    )
                                                )
                                            }
                                        >
                                            {procedure.name}
                                        </div>
                                    );
                                })}
                            </>
                        )}
                    </h3>
                </div>
            )}
            {filteredProcurements
                .slice(
                    navCurrentState * navPageLimit - navPageLimit,
                    navCurrentState * navPageLimit
                )
                .map((procurement) => {
                    const date = formatDate(procurement.date);

                    return (
                        <div className="procurement-wrapper" key={procurement.title}>
                            <div className="procurement-date">
                                {date.monthStringShort}, {date.year}
                            </div>
                            <a
                                href={procurement.url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <h3>{procurement.title}</h3>
                            </a>
                            {procurement.status === false && (
                                <div className="procurement-ongoing">
                                    Pågående
                                </div>
                            )}
                            {procurement.status === true && (
                                <div className="procurement-done">
                                    Utført
                                </div>
                            )}
                            <div className="procurement-procedure">
                                {procurement.procedures.map((procedure) => (
                                    <span key={procedure.name}>
                                        {procedure.name}
                                    </span>
                                ))}
                            </div>
                            <div className="procurement-actor">
                                {procurement.actors.map((actor) => (
                                    <span key={actor.title}>{actor.title}</span>
                                ))}
                            </div>
                        </div>
                    );
                })}
            {filteredProcurements.length > 5 && (
                <MultiPageNavigator
                    currentNumber={navCurrentState}
                    setCurrentNumber={(val) => setNavCurrentState(val)}
                    maxNumbers={navMax}
                />
            )}
        </div>
    );
};

Procedures.propTypes = {};

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        procurements: state.procurements,
        ...ownProps,
    };
};

export default connect(mapStateToProps)(Procedures);
