import { useEffect } from 'react';
import ApiManager from 'ApiManager';
import GlobalActions from 'reducers/globalReducer';

const Api = new ApiManager();

const useGlobal = () =>
    useEffect(() => {
        Api.getGlobals()
            .then((response) => {
                GlobalActions.setGlobal(response.data);
                // ActorActions.setActor(userResponse.data.actor);
                // Redirect successfully!
            })
            .catch((error) => {
                console.log('ERROR [getGlobals]', error);
            });
    }, []);

export default useGlobal;
