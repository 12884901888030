import React from 'react';
import { connect } from 'react-redux';

import ContactCard from 'components/ContactCard';

import arrow from 'images/svg/arrow.svg';
import wordFile from 'images/svg/wordFile.svg';

const file = (item, rotate) => {
    return (
        <div className="file-wrapper" key={item.name}>
            {item.is_file && (
                <>
                    <div className="file-name">
                        <a href={item.file.url} download>
                            <img src={wordFile} alt="dokument" />
                            <span className="bold">{item.name}</span>
                        </a>
                    </div>
                    <div className="arrow-wrapper">
                        <a href={item.file.url} download>
                            {' '}
                            <img src={arrow} alt="Last ned fil" />
                        </a>
                    </div>
                </>
            )}
            {!item.is_file && (
                <>
                    <div className="file-name">
                        <a
                            href={item.link}
                            download
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {' '}
                            <span className="bold">{item.name}</span>
                        </a>
                    </div>
                    <div className="arrow-wrapper">
                        <a href={item.link} download>
                            {' '}
                            <img src={arrow} alt="" />
                        </a>
                    </div>
                </>
            )}
        </div>
    );
};

const Examples = (props) => {
    const { user, options } = props;

    return (
        <>
            <div className="page-examples-wrapper">
                <div className="inner-wrapper">
                    <h2>Malverk</h2>
                    <div className="small-intro">
                        Her finner du ulike maler for innovative anskaffelser
                    </div>
                    <div className="small-file-wrapper">
                        {options.malverk &&
                            options.malverk.map((verk) => {
                                return file(verk, true);
                            })}
                    </div>
                </div>
                <div className="inner-wrapper">
                    <h2>Eksempler på beste praksis</h2>
                    <p>
                        Her finner du de beste eksemplene fra våre partnere.
                        Bruk gjerne disse som inspirasjon.
                    </p>
                    <div>
                        {options.best_practise &&
                            options.best_practise.map((best) => {
                                return file(best, false);
                            })}
                    </div>
                </div>

                <div className="contact-card-wrapper">
                    <h2>Kontaktperson</h2>
                    <ContactCard
                        name={user.actor.promoter.name}
                        title={user.actor.promoter.title}
                        email={user.actor.promoter.email}
                        phone={user.actor.promoter.phone}
                        imageSrc={user.actor.promoter.image}
                    />
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...state.user,
        ...state.global,
        ...ownProps,
    };
};

export default connect(mapStateToProps)(Examples);
