/* eslint-disable */
import store from 'store';

const defaultState = [];

const SET_PROCUREMENT = 'SET_PROCUREMENT';

export const ProcurementReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_PROCUREMENT:
            return action.procurement;
        default:
            return state;
    }
};

export default {
    setProcurement: (procurementObject) => {
        store.dispatch({
            type: SET_PROCUREMENT,
            procurement: procurementObject,
        });
    },
};
