import React from 'react';
import * as PropTypes from 'prop-types';

const Button = ({ primary, onClick, children, className }) => {
  return (
    <button className={`btn ${primary ? 'primary' : ''} ${className ? className : ''} `} onClick={onClick}>
        {children}
    </button>
  );
}

Button.propTypes = {
  primary: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

Button.defaultProps = {
  primary: false,
  onClick: undefined,
  className: '',
};

export default Button;
