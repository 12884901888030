import { useEffect } from 'react';
import ApiManager from 'ApiManager';
import ProcurementActions from 'reducers/procurementReducer';

const Api = new ApiManager();

const useProcurements = () =>
    useEffect(() => {
        Api.getAllProcurements()
            .then((response) => {
                ProcurementActions.setProcurement(response.data);
                // ActorActions.setActor(userResponse.data.actor);
                // Redirect successfully!
            })
            .catch((error) => {
                console.log('ERROR [getGlobals]', error);
            });
    }, []);

export default useProcurements;
