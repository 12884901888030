import React from 'react';

import FileList from 'components/FileList';

const possibleFiles = [
    {
        name: 'Anskaffelsesstrategi',
    },
    {
        name: 'Partnerplan',
    },
    {
        name: 'Kontraktstrategi',
    },
    {
        name: 'Målstyring',
    },
];

const Strategies = ({ actor, editable }) => {
    if (!actor) return null;

    return (
        <>
            <div className="strategies-intro">
                Dokumentene er synlige for de andre partnerne i nettverket, og
                hjelper oss å validere implementering av innovasjonsvennlig
                anskaffelsespraksis.
            </div>
            {(editable || actor.documents.length > 0) && (
                <FileList
                    possibleFiles={possibleFiles}
                    files={actor.documents}
                    editable={editable}
                />
            )}
        </>
    );
};

export default Strategies;
