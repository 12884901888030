import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';

import Paadriver from 'images/Paadriver.png';
import CheckedIcon from 'images/checkbox-checked.svg';
import CheckedIconHover from 'images/checkbox-checked-hover.svg';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

function getCheckedIcon(hover) {
    if (getWindowDimensions().width > 787 && !hover) {
        return CheckedIcon;
    }
    return CheckedIconHover;
}

const AmbassadorCard = ({ title = '', text = '', options = '' }) => {
    const [checkIcon, setCheckIcon] = useState(CheckedIcon);

    useEffect(() => {
        function handleResize() {
            if (getWindowDimensions().width > 787) {
                setCheckIcon(CheckedIcon);
            } else {
                setCheckIcon(CheckedIconHover);
            }
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="ambassador-card" 
            onMouseEnter={() => {
                setCheckIcon(getCheckedIcon(true));
            }}
            onMouseLeave={() => {
                setCheckIcon(getCheckedIcon(false));
            }}
        >
            <div className="image-wrapper">
                <img alt="art" src={Paadriver} />
            </div>
            <div className="text-content">
                <span>
                    <h2>Derfor bør du bli partnerpådriver</h2>
                </span>
                <span>
                    <img alt="art" src={checkIcon} />
                    <p>Utvikle fagområdet</p>
                </span>
                <span>
                    {' '}
                    <img alt="art" src={checkIcon} />
                    <p>Forankre internt</p>
                </span>
                <span>
                    {' '}
                    <img alt="art" src={checkIcon} />
                    <p>Utvikle eget nettverk</p>
                </span>
                <span>
                    {' '}
                    <img alt="art" src={checkIcon} />
                    <p>Eget pådriverteam</p>
                </span>
            </div>
        </div>
    );
};

AmbassadorCard.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    options: PropTypes.object.isRequired,
};

export default AmbassadorCard;
