import React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import timeStampToObject from 'parsers/timeStampToObject';

class Procurements extends React.Component {
    componentDidMount() {}

    formatDate(dateString) {
        const unix = new Date(dateString).getTime() / 1000;
        return timeStampToObject(unix);
    }

    render() {
        const { wpUrl, procurements } = this.props;
        if (!procurements > 0) return <div />;

        return (
            <div className="procurement-wrapper">
                {procurements.map((procurement) => {
                    const date = this.formatDate(procurement.date);
                    return (
                        <div className="procurement" key={procurement.id}>
                            <div className="circle"></div>
                            <div className="date">
                                {date.monthString}, {date.year}
                            </div>
                            <div className="title">
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href={`${wpUrl}/${procurement.url}`}
                                >
                                    {procurement.title_short}
                                </a>

                                {procurement.status !== 'done' && (
                                    <div className="ongoing">
                                        Pågående
                                    </div>
                                )}
                            </div>
                            {procurement.status === 'done' && (
                                <div className="done">Utført</div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...state.global,
        ...ownProps,
    };
};

Procurements.propTypes = {
    wpUrl: PropTypes.string.isRequired,
    procurements: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(Procurements);
