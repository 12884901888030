import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import ApiManager from 'ApiManager';

import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import ContactCard from 'components/ContactCard';
import CourseHeader from 'components/Courses/CourseHeader';

import artBoard from 'images/artboard.svg';
import camera from 'images/svg/camera';
import Spinner from 'images/svg/spinner';

import UserActions from 'reducers/userReducer';

const logout = () => {
    UserActions.logOutUser();
};

const EditUserPage = (props) => {
    const { user, options, setTokenState } = props;
    const fileInput = useRef(null);

    const [imageState, setImageState] = useState(false);
    const [imagePreviewState, setImagePreviewState] = useState(user?.image);
    const [uploadingState, setUploadingState] = useState(false);
    const [imageUploadError, setImageUploadError] = useState(false);
    const [editState, setEditState] = useState(false);
    const [attemptState, setAttemptState] = useState(false);
    const [forumState, setForumState] = useState(!!user.forum);

    const [nameState, setNameState] = useState(user?.name);
    const [titleState, setTitleState] = useState(user?.acf?.title);
    const [phoneState, setPhoneState] = useState(user?.acf?.phone);

    const [editPasswordState, setEditPasswordState] = useState(false);

    const [passwordState, setPasswordState] = useState('');
    const [passwordState2, setPasswordState2] = useState('');
    const [passwordError, setPasswordError] = useState(false);

    const saveChanges = () => {
        const Api = new ApiManager();
        setAttemptState(true);
        if (
            editPasswordState &&
            (passwordState !== passwordState2 || passwordState === '')
        ) {
            setPasswordError(true);
            return;
        }
        setPasswordError(false);

        Api.editCurrentUser(
            nameState,
            titleState,
            editPasswordState,
            passwordState,
            forumState,
            phoneState
        )
            .then(() => {
                Api.getLoggedInUser().then((userResponse) => {
                    UserActions.logInUser(false, userResponse.data);
                });
            })
            .finally(() => {
                setEditState(false);
                setAttemptState(false);
            });
    };

    const saveImage = (image, objectUrl) => {
        const Api = new ApiManager();
        Api.editCurrentUserImage(image)
            .then(() => {
                Api.getLoggedInUser().then((userResponse) => {
                    UserActions.logInUser(false, userResponse.data);
                });
                setImagePreviewState(objectUrl);
                setImageUploadError(false);
            })
            .catch((error) => {
                console.log('error', error);
                setImageUploadError(true);
            })
            .finally(() => {
                setUploadingState(false);
            });
    };
    const uploadImage = (e) => {
        const image = e.target.files[0];
        if (image) {
            setUploadingState(true);
            const objectUrl = URL.createObjectURL(image);
            setImageState(image);
            saveImage(image, objectUrl);
        } else {
            setImagePreviewState(user.image);
        }
    };
    if (!user.email) {
        window.location.reload();
        return <div />;
    }
    return (
        <div className="page-edit-user-wrapper">
            <>
                <input className="image-input"
                    type="file"
                    accept="image/*"
                    ref={fileInput}
                    onChange={(e) => uploadImage(e)}
                />
                <div className="top-wrapper">
                    <div className="left">
                        <div>
                            <h1>{user.name}</h1>
                            <div>{user.acf.title}</div>
                            <div>{user.actor.title}</div>
                            <div>{user.email}</div>
                        </div>
                        <div className="double-buttons">
                            <Button
                                primary
                                onClick={() => setEditState(!editState)}
                            >
                                <span>Rediger profil</span>
                            </Button>
                            <Button onClick={() => logout()}>
                                <span>Logg ut</span>
                            </Button>
                        </div>
                    </div>
                    <div className="right">
                        <div className="image-wrapper">
                            <div
                                className={!uploadingState ? 'click' : undefined}
                                onClick={() =>
                                    !uploadingState && fileInput.current.click()
                                }
                            >
                                {!uploadingState && camera}
                                {uploadingState && (
                                    <div>
                                        <Spinner />
                                    </div>
                                )}
                                <img src={imagePreviewState} alt={user.name} />
                            </div>
                        </div>
                        {imageUploadError && <div>Opplasting feilet</div>}
                    </div>
                </div>

                <div className={`edit-wrapper ${editState ? 'open' : ''}`}>
                    <div className="fields-wrapper">
                        <span>Navn</span>
                        <input
                            type="text"
                            onChange={(e) => setNameState(e.target.value)}
                            value={nameState}
                        />
                        <span>Tittel</span>
                        <input
                            type="text"
                            onChange={(e) => setTitleState(e.target.value)}
                            value={titleState}
                        />
                        <span>Telefon</span>
                        <input
                            type="text"
                            onChange={(e) => setPhoneState(e.target.value)}
                            value={phoneState}
                        />
                        <span>Motta epost fra forum</span>
                        <Checkbox
                            onClick={() => {
                                setForumState(!forumState);
                            }}
                            checked={forumState}
                        />
                        <div className="password">
                            <div>
                                <div>
                                    <span>Passord</span>
                                    <input
                                        type="password"
                                        onChange={(e) =>
                                            setPasswordState(e.target.value)
                                        }
                                        placeholder="**************"
                                        value={passwordState}
                                        className={
                                            attemptState &&
                                            editPasswordState ?
                                            'error' : undefined
                                        }
                                    />
                                </div>
                                <Button
                                    onClick={() =>
                                        setEditPasswordState(!editPasswordState)
                                    }
                                >
                                    Endre passord
                                </Button>
                            </div>
                            {editPasswordState && (
                                <>
                                    <span>Bekreft passord</span>
                                    <input
                                        type="password"
                                        onChange={(e) =>
                                            setPasswordState2(e.target.value)
                                        }
                                        placeholder="**************"
                                        value={passwordState2}
                                    />
                                    {passwordError && passwordState !== '' && (
                                        <h3>Passordene er ikke like!</h3>
                                    )}
                                </>
                            )}
                        </div>

                        <div className="double-buttons">
                            <Button primary onClick={() => saveChanges()}>
                                Lagre og lukk
                            </Button>
                            <Button onClick={() => setEditState(false)}>
                                Angre og lukk
                            </Button>
                        </div>
                    </div>
                </div>
            </>
            
            <CourseHeader
                options={options}
                image={artBoard}
                title="Bli pådriver"
                text="Gjennomfør obligatorisk program og to selvvalgte kurs for å bli pådriver og få studietur dekket."
            />
            <div className="contact-card-wrapper">
                <h2>Kontaktperson</h2>
                <ContactCard
                    name={user.actor.promoter.name}
                    title={user.actor.promoter.title}
                    email={user.actor.promoter.email}
                    phone={user.actor.promoter.phone}
                    imageSrc={user.actor.promoter.image}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...state.user,
        ...state.global,
        ...ownProps,
    };
};

EditUserPage.propTypes = {
    user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(EditUserPage);
