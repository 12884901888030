import * as React from 'react';

function whiteArrow(props) {
    return (
        <svg width={14} height={14} viewBox="0 0 14 14" fill="none" {...props}>
            <path d="M5 2.5L9.5 7 5 11.5M9.5 7H0" stroke="#fff" />
        </svg>
    );
}

export default whiteArrow;
