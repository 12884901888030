import React, { useRef } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import Procurements from './procurements';
import Team from './team';
import Strategies from './strategies';

import Accordion from 'components/Accordion';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import ContactCard from 'components/ContactCard';
import StepProgressBar from 'components/StepProgressBar';

import UserIcon from 'images/user-icon.svg';
import AnskaffelserIcon from 'images/anskaffelser.svg';
import DocumentIcon from 'images/document.svg';


const getDocumentStatus = (type, documents) => {
    return documents
        .filter((val) => val.name === type)
        .map((document) => {
            return document.uploaded;
        })[0];
};

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 250);

const PartnerPage = (props) => {
    const { user } = props;
    const { actor } = user;
    const { documents } = actor;
    const uploadRef = useRef(null);
    const innovationSteps = [
        {
            icon: (
                <Checkbox
                    checked={
                        getDocumentStatus('Anskaffelsesstrategi', documents) ||
                        getDocumentStatus('Virksomhetsstrategi', documents)
                    }
                />
            ),
            label: 'Anskaffelsesstrategi',
            content: (
                <div>
                    <p>
                        Anskaffelsesstrategi der innovative anskaffelser er
                        omtalt som strategisk virkemiddel for måloppnåelse.
                    </p>
                    {!getDocumentStatus('Anskaffelsesstrategi', documents) &&
                        !getDocumentStatus(
                            'Virksomhetsstrategi',
                            documents
                        ) && (
                            <Button
                                primary
                                onClick={() => scrollToRef(uploadRef)}
                            >
                                Last opp
                            </Button>
                        )}
                </div>
            ),
        },
        {
            icon: <Checkbox checked={actor.innovation.innovation} />,
            label: 'Innovasjonsvennlig anskaffelse',
            content: (
                <div>
                    <p>
                        Virksomheten har gjennomført minst én innovasjonsvennlig
                        anskaffelse
                    </p>
                </div>
            ),
        },
        {
            icon: <Checkbox checked={actor.innovation.procurement} />,
            label: 'Anskaffelse av innovasjon',
            content: (
                <div>
                    <p>
                        Virksomheten har gjennomført minst én anskaffelse av
                        innovasjon (plan- og designkonkurranse,
                        konkurransepreget dialog, Innovasjonspartnerskap eller
                        før-kommersiell anskaffelse/Fou-unntaket)
                    </p>
                </div>
            ),
        },
        {
            icon: <Checkbox checked={actor.members.length >= 1} />,
            label: 'Tverrfaglige team',
            content: (
                <div>
                    <p>
                        Virksomheten har etablert et tverrfaglig team (+2
                        personer fra to ulike avdelinger) i partnerportalen.
                    </p>
                </div>
            ),
        },
        {
            icon: (
                <Checkbox
                    checked={getDocumentStatus('Kontraktstrategi', documents)}
                />
            ),
            label: 'Kontraktstrategi eller annen rutinebeskrivelse',
            content: (
                <div>
                    <p>
                        Kontraktsstrategi eller annen rutinebeskrivelse som
                        sørger for at alle anskaffelser over 100 000,- blir
                        gjenstand for en vurdering om den kan/bør kjøres som en
                        innovativ anskaffelse.
                    </p>
                    {!getDocumentStatus('Kontraktstrategi', documents) && (
                        <Button primary onClick={() => scrollToRef(uploadRef)}>
                            Last opp
                        </Button>
                    )}
                </div>
            ),
        },
        {
            icon: (
                <Checkbox
                    checked={getDocumentStatus('Målstyring', documents)}
                />
            ),
            label: 'Målstyring og opplæring for IOA',
            content: (
                <div>
                    <p>
                        Virksomheten måler hvor mange av virksomhetens
                        anskaffelser som er innovative anskaffelser, og har på
                        plass en formalisert rutine for at nye innkjøpere og
                        prosjektledere får opplæring i hvordan anskaffelser kan
                        brukes strategisk for økt innovasjon.
                    </p>
                    {!getDocumentStatus('Målstyring', documents) && (
                        <Button primary onClick={() => scrollToRef(uploadRef)}>
                            Last opp
                        </Button>
                    )}
                </div>
            ),
        },
    ];
    const yourActorTools = [
        {
            icon: <img src={UserIcon} alt="Team" />,
            label: 'Team',
            content: (
                <Team
                    isAdmin={user.admin}
                    administrators={user.actor.administrator}
                    members={user.actor.members}
                />
            ),
        },
        {
            icon: <img src={AnskaffelserIcon} alt="Anskaffelser" />,
            label: 'Anskaffelser',
            content: <Procurements procurements={actor.procurements} />,
        },
        {
            icon: <img src={DocumentIcon} alt="Strategier og planer" />,
            label: 'Strategier og planer',
            content: <Strategies actor={actor} />,
        },
    ];
    return (
        <div className="page-partner-wrapper">
            <div className="partner-info">
                <div className="partner-branding">
                    {!actor.logo && <h1>{actor.title}</h1>}
                    {actor.logo && <img src={actor.logo} alt={actor.logo} />}
                </div>

                <ContactCard
                    small
                    name={actor.promoter.name}
                    title={actor.promoter.title}
                    email={actor.promoter.email}
                    phone={actor.promoter.phone}
                    imageSrc={actor.promoter.image}
                />
            </div>

            <div className="innovation-steps">
                <h2>Bli en mer innovasjonsvennlig innkjøpsvirksomhet</h2>
                <StepProgressBar
                    currentNumber={actor.innovation_progress}
                    maxNumbers={innovationSteps.length}
                    large
                />
                <p>
                    Din virksomhet har gjennomført {actor.innovation_progress}
                    {' av '}
                    {innovationSteps.length} tiltak for en mer
                    innovasjonsvennlig anskaffelsespraksis.
                </p>

                <Accordion items={innovationSteps} />
            </div>

            <div className="actor-tools">
                <h2>Din virksomhet</h2>

                <Accordion items={yourActorTools} />

                <div className="actor-tools-desktop">
                    <div className="half-width">
                        <h3>Team</h3>
                        <Team
                            isAdmin={user.admin}
                            administrators={user.actor.administrator}
                            members={actor.members}
                        />
                    </div>
                    <div className="half-width">
                        <h3>Anskaffelser</h3>
                        <Procurements procurements={actor.procurements} />
                    </div>
                </div>
                <div className="strategies-wrapper" ref={uploadRef}>
                    <h2>Strategier og planer</h2>
                    <Strategies editable actor={actor} />
                </div>
            </div>

            <div className="contact-card-wrapper">
                <h2>Kontaktperson</h2>
                <ContactCard
                    name={user.actor.promoter.name}
                    title={user.actor.promoter.title}
                    email={user.actor.promoter.email}
                    phone={user.actor.promoter.phone}
                    imageSrc={user.actor.promoter.image}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...state.user,
        ...ownProps,
    };
};

PartnerPage.propTypes = {
    user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(PartnerPage);

