import React, { useState } from 'react';
import * as PropTypes from 'prop-types';

import ForumUploadFile from 'pages/ForumEntryPage/forumUploadFile';

const ForumUpload = (props) => {
    // const fileList = makeFileList([], files);

    const { progressState, uploadArray, setUploadArray, editable } = props;

    const [editMode, setEditMode] = useState(editable);
    const [errorState, setErrorState] = useState(false);

    const removeFile = (name) => {
        setUploadArray(
            uploadArray.filter((item) => {
                return item.uploadedFile.name !== name;
            })
        );
    };

    return (
        <div className="forum-upload-wrapper">
            <div className="content">
                {editMode && (
                    <div className="file-list">
                        <ForumUploadFile
                            key="new"
                            file={{ name: `vedlegg` }}
                            editMode={editMode}
                            setEditMode={setEditMode}
                            setUploadArray={(val) => {
                                setUploadArray([...uploadArray, { ...val }]);
                                setErrorState(false);
                            }}
                            removeFile={(val) => removeFile(val)}
                            progress={progressState}
                        />
                        {uploadArray &&
                            uploadArray.map((file, key) => {
                                return (
                                    <ForumUploadFile
                                        key={file.name + key}
                                        file={file}
                                        editMode={editMode}
                                        setEditMode={setEditMode}
                                        setUploadArray={(val) => {
                                            setUploadArray([
                                                ...uploadArray,
                                                { ...val },
                                            ]);
                                            setErrorState(false);
                                        }}
                                        progress={progressState}
                                        removeFile={(val) => removeFile(val)}
                                    />
                                );
                            })}
                    </div>
                )}
                {!editMode && (
                    <div className="file-list">
                        {uploadArray.length > 0 && <h3>Vedlegg</h3>}
                        {uploadArray &&
                            uploadArray.map((file, key) => {
                                return (
                                    <ForumUploadFile
                                        key={file.name + key}
                                        file={file}
                                        editMode={editMode}
                                        setEditMode={setEditMode}
                                        setUploadArray={(val) => {
                                            setUploadArray([
                                                ...uploadArray,
                                                { ...val },
                                            ]);
                                            setErrorState(false);
                                        }}
                                        progress={progressState}
                                    />
                                );
                            })}
                    </div>
                )}
                <div className="error">{errorState}</div>
            </div>
        </div>
    );
};

ForumUpload.propTypes = {
    uploadArray: PropTypes.array.isRequired,
    editable: PropTypes.bool,
};

ForumUpload.defaultProps = {
    editable: false,
};

export default ForumUpload;
