import React from 'react';
import * as PropTypes from 'prop-types';

import ApiManager from 'ApiManager';

import hamburger from 'images/svg/hamburger';


const Api = new ApiManager();

const GreetingToast = ({ name, promoter, setToastState }) => {
    return (
        <div className="greeting-toast">
            <div className="close"
                onClick={() => {
                    Api.setClosedToast().then(() => {
                        setToastState(false);
                    });
                }}
            >
                {hamburger(true)}
            </div>
            <div className="image-wrapper">
                <img src={promoter.image} alt={name} />
            </div>
            <div className="text">
                <h3>Hei {name}!</h3>
                <div> Velkommen til Partnerportalen.</div>
                <div>
                    Her finner du dine verktøy for
                    leverandørutvilkingsprogrammet, samlet på ett sted.
                </div>
                <br />
                <div>
                    Hilsen <span>{promoter.name}</span>, din pådriver fra{' '}
                    <abbr title="Nasjonalt program for leverandørutvikling">
                        LUP.
                    </abbr>
                </div>
                <div>
                    <a target="_blank" href={`mailto:${promoter.email}`}>
                        {promoter.email}
                    </a>
                    , tel. <span>{promoter.phone}</span>
                </div>
            </div>
        </div>
    );
};

GreetingToast.propTypes = {
    name: PropTypes.string.isRequired,
    promoter: PropTypes.object.isRequired,
    setToastState: PropTypes.func.isRequired,
};
export default GreetingToast;
