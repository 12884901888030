import React from 'react';

import CourseHeader from 'components/Courses/CourseHeader';

import artBoard from 'images/artboard.svg';

import timeStampToObject from 'parsers/timeStampToObject';

const course = (item) => {
    const start = timeStampToObject(item.start);
    return (
        <li className="course" key={item.id}>
            <a href={`kurs/${item.slug}`}>
                <span>{item.title}</span>
                <span>{`${start.day}. ${start.monthString}`}</span>
                <span
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{
                        __html: item.acf.calendar_location,
                    }}
                />
            </a>
        </li>
    );
};

const CourseList = ({ events, options }) => {
    return (
        <div className="courses-list">
            <h2>Kurs og opplæring</h2>
            <CourseHeader
                options={options}
                image={artBoard}
                title="Bli pådriver"
                text="Gjennomfør obligatorisk program og tre selvvalgte kurs for å bli pådriver og få studietur dekket."
            />
            <div className="top">
                <h3>Neste kurs/aktivitet</h3>
                <a href="/kurs">Se alle kurs/aktivitet</a>
            </div>
            <ul className="courses">
                {events.map((item) => {
                    return course(item);
                })}
            </ul>
        </div>
    );
};

export default CourseList;
