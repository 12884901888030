import React from 'react';

import ContactCard from 'components/ContactCard';

const CourseSpeakers = ({ data }) => {
    return (
        <>
            <div className="course-speakers">courseSpeakers
                <h3>Foredragsholdere</h3>
                {data.speakers.map((speaker) => {
                    return (
                        <ContactCard
                            key={speaker.phone}
                            name={speaker.name}
                            title={speaker.title}
                            email={speaker.email}
                            phone={speaker.phone}
                            imageSrc={speaker.image}
                            small
                        />
                    );
                })}
            </div>
        </>
    );
};

CourseSpeakers.propTypes = {};

export default CourseSpeakers;
