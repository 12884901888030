/* eslint-disable */
import store from 'store';

const defaultState = {
    authToken: false,
    user: {},
    isAdmin: false,
};

const LOGGED_IN_USER = 'LOGGED_IN_USER';
const LOGGED_OUT_USER = 'LOGGED_OUT_USER';

export const UserReducer = (state = defaultState, action) => {
    switch (action.type) {
        case LOGGED_IN_USER:
            return {
                ...{},
                ...state,
                ...{
                    authToken: action.authToken
                        ? action.authToken
                        : state.authToken,
                    user: {
                        ...action.user.user,
                        actor: { ...action.user.actor },
                    },
                    isAdmin: !!action.user.admin,
                },
            };
        case LOGGED_OUT_USER:
            return {
                ...{},
                ...defaultState,
            };
        default:
            return state;
    }
};

export default {
    logInUser: (authToken, userObject) => {
        store.dispatch({
            type: LOGGED_IN_USER,
            authToken: authToken,
            user: userObject,
        });
    },
    logOutUser: () => {
        store.dispatch({
            type: LOGGED_OUT_USER,
        });
    },
};
