import React, { useState } from 'react';
import * as PropTypes from 'prop-types';

import ApiManager from 'ApiManager';

import Button from 'components/Button';

import hamburger from 'images/svg/hamburger';

import UserActions from 'reducers/userReducer';

const Api = new ApiManager();

const AddMember = ({ setShowForm }) => {
    const [formData, setFormData] = React.useState({
        name: '',
        title: '',
        email: '',
    });
    const [responseData, setResponseData] = React.useState(false);

    const inviteMember = () => {
        Api.addMember(formData).then((response) => {
            setResponseData({
                ...response.data,
            });

            if (!response.data.email_taken) {
                Api.getLoggedInUser().then((userResponse) => {
                    UserActions.logInUser(false, userResponse.data);
                    setShowForm(false);
                });
            }
        });
    };

    function handleChange(evt) {
        const { value } = evt.target;
        setFormData({
            ...formData,
            [evt.target.name]: value,
        });
    }
    return (
        <div className="add-member-overlay">
            <div className="add-member-wrapper">
                <button className="close" title="Close"
                    onClick={() => {
                        setShowForm(false);
                    }}
                >
                    {hamburger(true)}
                </button>
                <h2>Legg til ny bruker</h2>
                <p>
                    Brukeren må selv trykke på lenken han/hun får tilsendt på
                    e-post for å fullføre registreringen
                </p>
                <div className="add-member-fields">
                    <span>Navn</span>
                    <input
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                    <span>Tittel</span>
                    <input
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                    />
                    <span>Epost</span>
                    <input
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                </div>
                <div className="double-buttons">
                    <Button primary onClick={() => inviteMember()}>
                        <span>Legg til bruker</span>
                    </Button>
                    <Button
                        onClick={() => {
                            setShowForm(false);
                        }}
                    >
                        <span>Angre og lukk</span>
                    </Button>
                </div>
                {responseData.email_taken && (
                    <span>Epost er allerede registrert</span>
                )}
                {responseData && <span>{responseData.url}</span>}
            </div>
        </div>
    );
};
AddMember.propTypes = {
    setShowForm: PropTypes.func.isRequired,
};

export default AddMember;
