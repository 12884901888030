import React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import PartnerLinks from 'components/PartnerLinks';

const ToolsPage = ({ partners }) => {
    return (
        <div className="page-other-actors-wrapper">
            <PartnerLinks left partners={partners} />
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...state.user,
        ...state.global,
        ...ownProps,
    };
};

ToolsPage.propTypes = {
    partners: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(ToolsPage);
