import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import * as PropTypes from 'prop-types';

import Button from 'components/Button';

import ArrowLeft from 'images/arrow-left.svg';
import difiSmall from 'images/partnerlogos/difiSmall.svg';
import forskningSmall from 'images/partnerlogos/forskningSmall.svg';
import innoSmall from 'images/partnerlogos/innoSmall.svg';
import ksSmall from 'images/partnerlogos/ksSmall.svg';
import nhoSmall from 'images/partnerlogos/nhoSmall.svg';

const Line = () => (
    <svg className="separator"
        width="2"
        height="12"
        viewBox="0 0 2 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.0643 0.21875H0.819916V11.7598H1.0643V0.21875Z"
            fill="#666666"
        />
    </svg>
);

const CustomForm = ({ status, message, onValidated }) => {
    let email;
    const submit = () =>
        email &&
        email.value.indexOf('@') > -1 &&
        onValidated({
            EMAIL: email.value,
        });

    if (status === 'success') {
        return (
            <div>
                Takk, du er påmeldt. Du må bekrefte din e-post via en tilsendt
                lenke
            </div>
        );
    }

    return (
        <div>
            {status === 'sending' && <div style={{ color: 'blue' }} />}
            {status === 'error' && (
                <div
                    style={{ color: 'red' }}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            {status === 'success' && <div style={{ color: 'green' }} />}
            <input
                /* eslint-disable-next-line no-return-assign */
                ref={(node) => (email = node)}
                type="email"
                placeholder="Din e-postadresse"
            />
            <Button primary onClick={submit}>
            Send
            </Button>
        </div>
    );
};
const Footer = ({ authToken, greyBackground }) => {
    const url =
        '//innovativeanskaffelser.us16.list-manage.com/subscribe/post?u=79786993b63ad06ea4e1ad85d&id=3d6ca35fee';

    return (
        <footer 
            className={`${greyBackground ? 'greyBackground' : ''} ${!!authToken ? 'authToken' : ''}`}
        >
            <a 
                href="https://www.innovativeanskaffelser.no" 
                className="footer-backlink" 
                target="_blank"
            >
                <img src={ArrowLeft} alt="" /> Til innovativeanskaffelser.no
            </a>
            <div className="footer-separator"></div>
            <div className="footer-bottom">
                <div>
                    <div className="footer-info">
                        <h2>Innovative anskaffelser</h2>
                        <p>Nasjonalt program for leverandørutvikling</p>
                    </div>
                    <div className="footer-logos">
                        <a
                            href="https://www.nho.no/"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="NHO"
                        >
                            <img src={nhoSmall} alt="" />
                        </a>
                        <Line />
                        <a
                            href="https://www.ks.no/"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="KS"
                        >
                            <img src={ksSmall} alt="" />
                        </a>
                        <Line />
                        <a
                            href="https://www.difi.no/"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Difi"
                        >
                            <img src={difiSmall} alt="" />
                        </a>
                        <Line />
                        <a
                            href="https://www.innovasjonnorge.no/"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Innovasjonnorge"
                        >
                            <img src={innoSmall} alt="" />
                        </a>
                        <Line />
                        <a
                            href="https://www.forskningsradet.no/"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Forskningsradet"
                        >
                            <img src={forskningSmall} alt="" />
                        </a>
                    </div>
                </div>
                <div className="footer-newsletter">
                    <h3>Vil du motta vårt nyhetsbrev?</h3>
                    <MailchimpSubscribe
                        url={url}
                        render={({ subscribe, status, message }) => (
                            <CustomForm
                                status={status}
                                message={message}
                                onValidated={(formData) =>
                                    subscribe(formData)
                                }
                            />
                        )}
                    />
                </div>
            </div>
        </footer>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...state.user,
        ...ownProps,
    };
};

Footer.propTypes = {
    authToken: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

Footer.defaultProps = {
    authToken: null,
};

export default connect(mapStateToProps)(withRouter(Footer));
