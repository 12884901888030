import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import ContactCard from 'components/ContactCard';

import Procedures from 'pages/DataBankPage/procedures';

const DataBank = (props) => {
    const {
        user,
        options,
        setGreyBackground,
        greyBackground,
        tokenState,
    } = props;

    useEffect(() => {
        if (greyBackground && !tokenState) {
            setGreyBackground(false);
        }
    }, [greyBackground, setGreyBackground, tokenState]);

    return (
        <>
            <div className="page-databank-wrapper">
                <h1>Database</h1>
                <div className="intro">
                    I databasen kan du søke deg fram til eksempler på innovative
                    anskaffelser etter kategori og anskaffelsesprosedyre. Klikk
                    deg videre for å se konkurransegrunnlaget.
                </div>

                <h2>Anskaffelsesprosedyrer</h2>
                <div className="small-intro">
                    Her kan du velge å se eksempler på anskaffelser som er
                    utført med forskjellige prosedyrer kan fjernes
                </div>

                <Procedures />

                {user?.actor?.promoter && (
                    <div className="contact-card-wrapper">
                        <h2>Kontaktperson</h2>
                        <ContactCard
                            name={user.actor.promoter.name}
                            title={user.actor.promoter.title}
                            email={user.actor.promoter.email}
                            phone={user.actor.promoter.phone}
                            imageSrc={user.actor.promoter.image}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...state.user,
        ...state.global,
        ...ownProps,
    };
};

export default connect(mapStateToProps)(DataBank);
