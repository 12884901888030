import React from 'react';
import * as PropTypes from 'prop-types';

import arrow from 'images/svg/arrow';
import whiteArrow from 'images/svg/whiteArrow';

const ToolLister = (tool, altMobile) => (
    <div className={`tool ${altMobile ? 'altMobile' : ''}`} key={tool.title}>
        {altMobile && (
            <div className="alt-mobile">
                <a href={tool.link}>{tool.title}</a>
                <a href={tool.link}>{arrow()}</a>
            </div>
        )}

        {!altMobile && (
            <div className="mobile">
                <a href={tool.link}>{tool.title}</a>
                <a href={tool.link}>{whiteArrow()}</a>
            </div>
        )}
        <a href={tool.link} className="desktop">
            <div>
                <div>{tool.title}</div>
                <div>{arrow()}</div>
            </div>
            <p>{tool.text}</p>
        </a>
    </div>
);

const Tools = ({ tools, email, subject, altMobile }) => {
    const mailLink = `mailto:${email}?subject=${subject}`;
    return (
        <div className={`tools ${altMobile ? 'altMobile' : ''}`}>
            {tools.map((tool) => {
                return ToolLister(tool, altMobile);
            })}

            <div className={`tool ${altMobile ? 'altMobile' : ''}`} key="Foreslå nye verktøy">
                {altMobile && (
                    <div className="alt-mobile">
                        <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={mailLink}
                        >
                            Foreslå nye verktøy
                        </a>
                        <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={mailLink}
                        >
                            {arrow()}
                        </a>
                    </div>
                )}

                {!altMobile && (
                    <div className="mobile">
                        <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={mailLink}
                        >
                            Foreslå nye verktøy
                        </a>
                        <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={mailLink}
                        >
                            {whiteArrow()}
                        </a>
                    </div>
                )}
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={mailLink}
                    className="desktop"
                >
                    <div>
                        <span>Foreslå nye verktøy</span>
                    </div>
                    <p>
                        <span>Send oss en e-post</span> med ønsker til verktøy
                        du kunne hatt nytte av.
                    </p>
                </a>
            </div>
        </div>
    );
};

Tools.propTypes = {
    tools: PropTypes.array.isRequired,
    email: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    altMobile: PropTypes.bool,
};
Tools.defaultProps = {
    altMobile: false,
};

export default Tools;
