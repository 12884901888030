import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import MultiPageNavigator from 'components/MultiPageNavigator';

import cross from 'images/svg/cross.svg';

import dialogEntryCard from 'pages/DialogPage/dialogEntryCard';

const DialogLister = ({ primary, entries, categoryFilter, setFilter }) => {
    const navPageLimit = 5;
    const [navState, setNavstate] = useState(1);
    const [navCurrentState, setNavCurrentState] = useState(1);

    const navMax =
        (entries.length + (navPageLimit - (entries.length % navPageLimit))) /
        navPageLimit;

    return (
        <div className="dialog-lister">
            <div className="title-wrapper">
                {!categoryFilter && <h2>Nyeste innlegg</h2>}
                {categoryFilter && (
                    <h2>
                        <span>{categoryFilter} </span>
                        <img src={cross} alt="remove filter" onClick={() => setFilter(false)} />
                    </h2>
                )}
                <Link to="forum/innlegg" className="btn">
                    Nytt innlegg +
                </Link>
            </div>
            <div className="list">
                {entries &&
                    entries
                        .filter((item) => {
                            if (!categoryFilter) return true;
                            return (
                                item.categories.filter((cat) => {
                                    return cat.name === categoryFilter;
                                }).length > 0
                            );
                        })
                        .slice(
                            navCurrentState * navPageLimit - navPageLimit,
                            navCurrentState * navPageLimit
                        )
                        .map((card) => {
                            return dialogEntryCard(card);
                        })}
            </div>

            {navMax >= 5 && (
                <MultiPageNavigator
                    currentNumber={navCurrentState}
                    setCurrentNumber={(val) => setNavCurrentState(val)}
                    maxNumbers={navMax}
                />
            )}
        </div>
    );
};

export default DialogLister;
