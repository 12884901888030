import React from 'react';
import { Link } from 'react-router-dom';

const DialogIntro = () => {
    return (
        <div className="dialog-intro">
            <div className="title-wrapper">
                <h1>Forum</h1>
                <Link to="forum/innlegg" className="btn">
                    Nytt innlegg +
                </Link>
            </div>
            <p>Del erfaringer og diskuter med partnernettverket</p>
        </div>
    );
};

DialogIntro.propTypes = {};

export default DialogIntro;
