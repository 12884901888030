import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Button from 'components/Button';

import useWindowSize from 'hooks/useWindowSize';

const DialogScroller = ({ primary, entries }) => {
    const size = useWindowSize();
    const [limit, setLimit] = useState(3);

    const isMobile = size.width < 992;

    return (
        <div className="dialog-scroller">
            <div className="tags">
                {entries
                    .slice(0, isMobile ? entries.length : limit)
                    .map((entry) => {
                        return (
                            <Link
                                className={`tag-wrapper ${!!primary ? 'primary' : ''}`}
                                key={entry.id}
                                to={`/forum/${entry.id}`}
                            >
                                <div>
                                    {entry.title}{' '}
                                    <span>{entry.author.name}</span>
                                </div>

                                {entry.categories.map((category) => {
                                    return (
                                        <div className="btn" key={category.name}>
                                            {category.name}
                                        </div>
                                    );
                                })}
                            </Link>
                        );
                    })}
            </div>
            {entries.length > limit && (
                <Button onClick={() => setLimit(limit + 3)}>
                    Vis fler +
                </Button>
            )}
        </div>
    );
};

export default DialogScroller;
