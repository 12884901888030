import { useEffect } from 'react';
import ApiManager from 'ApiManager';
import ForumActions from 'reducers/forumReducer';

const Api = new ApiManager();

const useForum = () =>
    useEffect(() => {
        Api.getForum()
            .then((response) => {
                ForumActions.setGlobal(response.data);
                // ActorActions.setActor(userResponse.data.actor);
                // Redirect successfully!
            })
            .catch((error) => {
                console.log('ERROR [getGlobals]', error);
            });
    }, []);

export default useForum;
