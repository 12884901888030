/* eslint-disable */
import store from 'store';

const defaultState = {
    forum: {},
};

const SET_FORUM = 'SET_FORUM';

export const ForumReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_FORUM:
            return {
                ...{},
                ...state,
                ...{
                    categories: action.forum.categories,
                    entries: action.forum.entries,
                },
            };
        default:
            return state;
    }
};

export default {
    setGlobal: (forumObject) => {
        store.dispatch({
            type: SET_FORUM,
            forum: forumObject,
        });
    },
};
