import React from 'react';
import { Link } from 'react-router-dom';
import * as PropTypes from 'prop-types';

import Arrow from 'images/svg/whiteArrow';

const GoBack = ({ text, to = false, wayBack = false, gone = false }) => {
    if (gone) {
        return (
            <div className="go-back">
                <Arrow />
                <a href="https://innovativeanskaffelser.no/">
                    Til innovativeanskaffelser.no
                </a>
            </div>
        );
    }
    if (wayBack) {
        return (
            <div className="go-back">
                <Arrow />
                <Link to="/">{text}</Link>
            </div>
        );
    }
    return (
        <div className="go-back">
            <Arrow />
            <Link to={to ? to : './'}>{text}</Link>
        </div>
    );
};

GoBack.propTypes = {
    text: PropTypes.string.isRequired,
    wayBack: PropTypes.bool,
    gone: PropTypes.bool,
};

GoBack.defaultProps = {
    wayBack: false,
    gone: false,
};
export default GoBack;
