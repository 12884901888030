import React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import ContactCard from 'components/ContactCard';


const PartnerLinks = ({ left, partners, user }) => {
    return (
        <div className="partner-links">
            {left && <h1>Partnere i nettverket</h1>}
            {!left && <h2>Partnere i nettverket</h2>}
            <div className={`partner-links-wrapper ${left ? 'left' : ''}`}>
                <ul>
                    {partners &&
                        partners.map((partner) => {
                            return (
                                <li key={partner.id}>
                                    <a href={`aktor/${partner.slug}`}>
                                        {partner.title}
                                    </a>
                                    <figure className="partner-links-image">
                                        {partner.logo && (
                                            <a href={`aktor/${partner.slug}`}>
                                                <img
                                                    src={partner.logo}
                                                    alt={partner.title}
                                                />
                                            </a>
                                        )}
                                    </figure>
                                </li>
                            );
                        })}
                </ul>
            </div>
            {user.actor && user.actor.promoter && (
                <>
                    <h2 className="partner-links-contact">Kontaktperson</h2>
                    <ContactCard
                        name={user.actor.promoter.name}
                        title={user.actor.promoter.title}
                        email={user.actor.promoter.email}
                        phone={user.actor.promoter.phone}
                        imageSrc={user.actor.promoter.image}
                    />
                </>
            )}
        </div>
    );
};

PartnerLinks.propTypes = {
    partners: PropTypes.array.isRequired,
    left: PropTypes.bool,
};

PartnerLinks.defaultProps = {
    left: false,
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...state.user,
        ...ownProps,
    };
};

export default connect(mapStateToProps)(PartnerLinks);
