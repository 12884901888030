import React from 'react';
import * as PropTypes from 'prop-types';

import whiteArrow from 'images/svg/whiteArrow';
import arrow from 'images/svg/arrow';
import Paadriver from 'images/Paadriver.png';

const CourseHeader = ({ title, text, options }) => {
    const staticTitle = 'Bli Partnerpådriver';
    const staticText = 'Bli med på læringssamlinger for interne pådriverteam.';
    return (
        <div className="courses-header">
            <div className="courses-header-image">
                <img alt="art" src={Paadriver} />
            </div>
            <a className="courses-header-text" href={options?.promoter_signup_url} rel="noopener noreferrer">
                <span>
                    <h2>{staticTitle}</h2>
                    <div className="whiteArrow">
                        <span>{whiteArrow()}</span>
                    </div>
                    <div className="arrow">
                        <span>{arrow()}</span>
                    </div>
                </span>
                <p>{staticText}</p>
            </a>
        </div>
    );
};

CourseHeader.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    options: PropTypes.object.isRequired,
};

export default CourseHeader;
