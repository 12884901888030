import React from 'react';

const CategoryFilter = ({ entries, setFilter }) => {
    return (
        <div className="category-filter">
            {/* <TitleWrapper> */}
            {entries && Object.keys(entries).length > 0 && (
                <h3>Eller utforsk etter tema</h3>
            )}
            {(!entries || Object.keys(entries).length < 1) && (
                <h3>Ingen treff</h3>
            )}
            {/* </TitleWrapper> */}
            <div className="filter-list">
                <ul>
                    {entries &&
                        Object.keys(entries).map((item, key) => {
                            const category = entries[item];
                            const number = Object.keys(category).length;
                            return (
                                <li key={item}>
                                    <div onClick={() => setFilter(item)}>
                                        <span>{item}</span>
                                        <span>({number})</span>
                                        <div className="number-badge">
                                            {number}
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                </ul>
            </div>
        </div>
    );
};

CategoryFilter.propTypes = {};

export default CategoryFilter;
