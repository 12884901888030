import React, { useState } from 'react';
import * as PropTypes from 'prop-types';

import ArrowDownIcon from 'images/arrow-down.svg';

const AccordionItem = ({ item }) => {
    const [isOpen,setIsOpen] = useState(false);

    const onClickHandler = () => setIsOpen(!isOpen);

    return (
        <li className="accordion-item" >
            <button className="accordion-item-header" 
                    onClick={onClickHandler}
                    aria-expanded={isOpen ? 'true' : 'false'}
                >
                <div className="accordion-item-icon">
                    {item.icon}
                </div>
                <div className="accordion-item-label">
                    {item.label}
                </div>
                {item.content && (
                    <img src={ArrowDownIcon} className={`accordion-item-toggle ${isOpen ? 'is-open' : ''}`} alt=""/>
                )}
            </button>
            {item.content && (
                <div className={`accordion-item-content ${isOpen ? 'visible' : ''}`}>
                    {item.content}
                </div>
            )}
        </li>
      )
}

export default AccordionItem;

AccordionItem.propTypes = {
    item: PropTypes.object.isRequired,
};
