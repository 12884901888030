import React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import Button from 'components/Button';
import ContactCard from 'components/ContactCard';
import CoursesAll from 'components/Courses/CoursesAll';
import CourseHeader from 'components/Courses/CourseHeader';
import Divider from 'components/Divider';

import Paadriver from 'images/Paadriver.png';

const Course = (props) => {
    const { user, options } = props;
    return (
        <div className="page-course-wrapper">
            <h1>Kurs og inspirasjon</h1>
            <CourseHeader
                options={options}
                image={Paadriver}
                title="Bli pådriver"
                text="Gjennomfør obligatorisk program og tre selvvalgte kurs for å bli pådriver og få studietur dekket."
            />
             {/* <Button primary>Legg til ditt kurs +</Button> */}
            <div className="divider-wrapper">
                <Divider />
            </div>
            <CoursesAll />
            <CoursesAll static />
            <div className="contact-card-wrapper">
                <h2>Kontaktperson</h2>
                <ContactCard
                    name={user.actor.promoter.name}
                    title={user.actor.promoter.title}
                    email={user.actor.promoter.email}
                    phone={user.actor.promoter.phone}
                    imageSrc={user.actor.promoter.image}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...state.user,
        ...state.global,
        ...ownProps,
    };
};

Course.propTypes = {
    user: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(Course);
