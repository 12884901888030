import React from 'react';
import * as PropTypes from 'prop-types';

const circle = (number) => (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none">
        <circle
            cx={16}
            cy={16}
            r={15}
            fill="#7C17FF"
            stroke="#DDDDDD"
            strokeWidth="1.2px"
        />
        <text fill="#ffffff" x="12" y="21">
            {number}
        </text>
    </svg>
);

const barSpan = (number, numberMax) => {
    const rows = [];
    for (let i = 1; i < numberMax + 1; i += 1) {
        if (i < number) rows.push(<span key={i} className="done" />);
        if (i === number)
            rows.push(
                <span key={i} className="done circle">
                    {circle(number)}
                </span>
            );
        if (i > number) rows.push(<span key={i} />);
    }
    return rows;
};

const StepProgressBar = ({ currentNumber, maxNumbers, large }) => {
    return (
        <div className={`step-progress-bar ${large ? 'large' : ''}`}>
            {barSpan(currentNumber, maxNumbers)}
        </div>
    );
};

StepProgressBar.propTypes = {
    currentNumber: PropTypes.number.isRequired,
    maxNumbers: PropTypes.number.isRequired,
    large: PropTypes.bool,
};

StepProgressBar.defaultProps = {
    large: false,
};

export default StepProgressBar;
