import React from 'react';

function partnerLogo() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="26"
            fill="none"
            viewBox="0 0 23 26"
        >
            <path
                fill="#CCC"
                d="M11.065 13.67C5.071 13.88.286 18.2.286 23.51c0 .83.765 1.5 1.713 1.5.948 0 1.713-.67 1.713-1.5 0-1.9.868-3.6 2.295-4.84 1.416-1.24 3.368-2 5.527-2.01-.948 0-1.713-.67-1.713-1.5 0-.75.628-1.38 1.461-1.48-.08 0-.148-.01-.217-.01z"
            />
            <path
                fill="#939598"
                d="M11.99 13.67c-.068 0-.137.01-.205.01.822.11 1.461.73 1.461 1.48 0 .83-.765 1.5-1.712 1.5 2.17 0 4.11.76 5.526 2.01 1.416 1.24 2.284 2.95 2.296 4.84 0 .83.765 1.5 1.712 1.5.948 0 1.713-.67 1.713-1.5-.011-5.31-4.796-9.63-10.79-9.84z"
            />
            <path
                fill="#76777A"
                d="M11.785 13.68h-.503c-.822.11-1.461.73-1.461 1.48 0 .83.765 1.5 1.713 1.5.947 0 1.712-.67 1.712-1.5 0-.75-.639-1.38-1.461-1.48z"
            />
            <path
                fill="#7C17FF"
                d="M11.534 0c-4.317 0-7.81 3.06-7.81 6.84 0-.83.764-1.5 1.712-1.5s1.713.67 1.713 1.5c0-1.07.49-2.02 1.279-2.72C9.227 3.43 10.312 3 11.534 3c1.221 0 2.306.43 3.105 1.12.8.7 1.28 1.65 1.28 2.72v.01-.01c0-.83.764-1.5 1.712-1.5s1.713.67 1.713 1.5c0-3.78-3.494-6.84-7.81-6.84z"
            />
            <path
                fill="#CCC"
                d="M19.344 6.84c0 .83-.765 1.5-1.713 1.5-.936 0-1.701-.66-1.713-1.49 0 1.06-.49 2-1.278 2.7-.8.7-1.885 1.12-3.106 1.12-1.222 0-2.307-.43-3.106-1.12-.8-.7-1.28-1.65-1.28-2.72 0 .83-.764 1.5-1.712 1.5s-1.713-.67-1.713-1.5c0 3.64 3.254 6.62 7.354 6.83.148-.01.308-.01.457-.01.16 0 .308 0 .456.01 4.1-.2 7.354-3.18 7.354-6.82z"
            />
            <path
                fill="#A3A3A3"
                d="M11.532 13.66c-.16 0-.307 0-.455.01.068 0 .136.01.205.01.08-.01.16-.02.25-.02z"
            />
            <path
                fill="#76777A"
                d="M11.534 13.66c.09 0 .17.01.25.02.069 0 .137 0 .205-.01-.148-.01-.307-.01-.455-.01z"
            />
            <path
                fill="#5E5F62"
                d="M11.533 13.66c-.09 0-.17.01-.25.02h.502c-.08-.01-.172-.02-.252-.02z"
            />
            <path
                fill="#6312CC"
                d="M5.436 5.34c-.948 0-1.713.67-1.713 1.5s.765 1.5 1.713 1.5c.948 0 1.713-.67 1.713-1.5s-.777-1.5-1.713-1.5zm12.195 0c-.948 0-1.713.67-1.713 1.5v.01c.012.82.777 1.49 1.713 1.49.948 0 1.713-.67 1.713-1.5s-.765-1.5-1.713-1.5z"
            />
        </svg>
    );
}

export default partnerLogo;
