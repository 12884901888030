import React from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

import Button from 'components/Button';

import ForumUpload from 'pages/ForumEntryPage/forumUpload';

import star from 'images/svg/star';

import timeStampToObject from 'parsers/timeStampToObject';

const DialogEntryCard = (
    entry,
    size,
    isReply = false,
    isOpen = false,
    line = false
) => {
    const isMobile = size ? size.width < 992 : false;

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };
    const scrollToBottom = () => {
        window.scrollTo(0, window.document.body.offsetHeight);
    };

    const date = timeStampToObject(entry.date);

    const onHandleContentURLConvert = (content) => {
        if (!content) return '';
        var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
        return content.replace(urlRegex, function (url) {
            var hyperlink = url;
            if (!hyperlink.match('^https?://')) {
                hyperlink = 'http://' + hyperlink;
            }
            return (
                '<a href="' +
                hyperlink +
                '" target="_blank" rel="noopener noreferrer">' +
                url +
                '</a>'
            );
        });
    };

    if (!isReply && isOpen && isMobile) {
        return (
            <div className="dialog-entry-card-mobile">
                <div className="mobile-name">
                    <span>{entry.author.name}</span>
                    <br />
                    {entry.actor}
                    <br />
                    {date.day}.{date.month}.{date.year} {date.hours}
                </div>
                <h1>{entry.title}</h1>
                <div className="mobile-text isOpen">
                    {onHandleContentURLConvert(entry.content)}
                </div>
                <div className="file-wrapper">
                    <ForumUpload uploadArray={entry.documents} />
                </div>
            </div>
        );
    }
    if (!isReply)
        return (
            <div className={`dialog-entry-card ${isOpen ? 'isOpen' : ''}`} key={entry.id}>
                <div className={`image ${isOpen ? 'isOpen' : ''}`}>
                    <div>
                        <img src={entry.author.image} alt={entry.author.name} />
                    </div>
                </div>
                <div className="text-wrapper primary">
                    {isOpen && (
                        <div className="top">
                            <Link
                                onClick={() => scrollToTop()}
                                to={`/forum/${entry.id}`}
                            >
                                <h3>
                                    {entry.author.name}, {entry.actor}
                                </h3>
                            </Link>
                            <span>{entry.author.title}</span>

                            <p className={`text ${isOpen ? 'isOpen' : ''}`}>
                                {parse(
                                    onHandleContentURLConvert(entry.content)
                                )}
                            </p>
                        </div>
                    )}
                    {!isOpen && (
                        <div className="top">
                            <Link
                                onClick={() => scrollToTop()}
                                to={`/forum/${entry.id}`}
                            >
                                <h3>{entry.title}</h3>
                            </Link>
                            <span>
                                {entry.author.name}, {entry.actor}
                            </span>
                            <p className={`text ${isOpen ? 'isOpen' : ''}`}>
                                {parse(
                                    onHandleContentURLConvert(entry.content)
                                )}
                            </p>
                        </div>
                    )}
                    {entry && (
                        <div className="file-wrapper">
                            <ForumUpload uploadArray={entry.documents} />
                        </div>
                    )}
                    <div className="bottom">
                        <div className="tag-wrapper">
                            {entry.categories.map((category) => {
                                return (
                                    <Button className="tag" key={category.name}>
                                        {category.name}
                                    </Button>
                                );
                            })}
                        </div>
                        {!isOpen && (
                            <div className="replies-wrapper">
                                {entry.replies.length > 0 && (
                                    <Link
                                        onClick={() => scrollToTop()}
                                        to={`/forum/${entry.id}`}
                                        className="btn tag"
                                    >
                                        {entry.replies.length} svar
                                    </Link>
                                )}
                                <Link
                                    onClick={() => scrollToBottom()}
                                    to={`/forum/${entry.id}`}
                                    className="btn tag"
                                >
                                    Svar +
                                </Link>
                            </div>
                        )}
                    </div>
                </div>

                <div className="star-wrapper">{entry.starred && star(true)}</div>
            </div>
        );

    if (isOpen && isMobile) {
        return (
            <div className="dialog-entry-card-mobile-reply">
                <div className="mobile-name">
                    <span>{entry.author.name}</span>
                    <br />
                    {entry.actor}
                    <br />
                    {date.day}.{date.month}.{date.year} {date.hours}
                </div>
                <div className="mobile-text isOpen">{entry.content}</div>
                {entry.documents.length > 0 && (
                    <div className="file-wrapper">
                        <ForumUpload uploadArray={entry.documents} />
                    </div>
                )}
            </div>
        );
    }
    return (
        <div className={`dialog-entry-card ${isOpen ? 'isOpen' : ''} ${line ? 'line' : ''}`}
            key={entry.id}
            to={`/forum/${entry.id}`}
        >
            <div className="image">
                <div>
                    <img src={entry.author.image} alt={entry.author.name} />
                </div>
            </div>
            <div className="text-wrapper primary">
                <div>
                    <span>
                        svar fra <div className="name">{entry.author.name}</div>
                    </span>
                    <span>
                        <br /> {entry.actor}
                    </span>
                </div>
                <p className={`text ${isOpen ? 'isOpen' : ''}`}>{entry.content}</p>
                {entry && isOpen && (
                    <div className="file-wrapper">
                        <ForumUpload uploadArray={entry.documents} />
                    </div>
                )}
                <div className="time">
                    <span>Postet</span> {date.day}. {date.monthString}{' '}
                    <span>klokken</span> {date.hours}
                </div>
            </div>
        </div>
    );
};

export default DialogEntryCard;
