import * as React from 'react';

function hamburger(menuState) {
    return menuState ? openBurger : closedBurger;
}

const openBurger = (
    <svg width={26} height={19} viewBox="0 0 26 19" fill="none">
        <g
            clipPath="url(#prefix__clip0)"
            strokeWidth={3}
            strokeMiterlimit={10}
            strokeLinecap="round"
        >
            <path d="M24.48 17.5l-16-16" stroke="#7C17FF" />
            <path
                d="M24.48 1.5l-16 16"
                stroke="#76777A"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="prefix__clip0">
                <path
                    transform="rotate(-180 25.98 19)"
                    fill="#fff"
                    d="M25.98 19h25.98v19H25.98z"
                />
            </clipPath>
        </defs>
    </svg>
);

const closedBurger = (
    <svg width={26} height={19} viewBox="0 0 26 19" fill="none">
        <g
            clipPath="url(#prefix__clip0)"
            strokeWidth={3}
            strokeMiterlimit={10}
            strokeLinecap="round"
        >
            <path d="M24.48 1.5H1.5" stroke="#7C17FF" />
            <path d="M24.48 9.5H1.5" stroke="#939598" />
            <path d="M24.48 17.5H1.5" stroke="#CCC" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="prefix__clip0">
                <path
                    transform="rotate(-180 25.98 19)"
                    fill="#fff"
                    d="M25.98 19h25.98v19H25.98z"
                />
            </clipPath>
        </defs>
    </svg>
);

export default hamburger;
