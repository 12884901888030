import React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import CoursesAll from 'components/Courses/CoursesAll';

import AmbassadorCard from 'pages/AmbassadorPage/ambassadorCard';

const Ambassador = (props) => {
    const { user, options } = props;
    return (
        <div className="page-ambassador-wrapper">
            <h1>Bli en del av Partnerpådriverprogrammet</h1>
            <AmbassadorCard />
            <CoursesAll limit={['Webinar', 'Opplæring', 'Studietur']} />
            <div className="text-wrapper">
                <h2>Om Partnerpådriver</h2>
                <h3>
                    Hvordan blir jeg en del av virksomhetens eget pådriverteam?
                </h3>
                <p>
                    Det kan være utfordrende å komme skikkelig i gang med, og
                    utnytte potensialet fullt ut av, innovative anskaffelser.
                    Basert på behov fra våre partnere tilbyr vi et kompetanse-
                    og praksisfellesskap for det som etter hvert skal bli
                    interne pådriverteam i hver partnervirksomhet. I tillegg til
                    oppdatert kunnskap om innovative anskaffelser skal vi dele
                    erfaringer om hvordan vi kan spre og øke bruken av
                    innovative anskaffelser i virksomhetene.
                </p>

                <h3>Hva lærer vi?</h3>
                <p>
                    Et internt partnerpådriverteam skal være en ressurs som skal
                    bidra til at virksomheten øker sin kompetanse for bedre å
                    kunne bruke offentlige anskaffelser som strategisk
                    virkemiddel for bedre tjenester. For innbyggerne,
                    næringslivet og seg selv. Det kan bidra til å øke
                    organisasjonens gjennomføringsevne til innovative
                    anskaffelser, samt til å nå mål i bruk av innovative
                    anskaffelser; deriblant innovasjonshøyde.​ Det kan også
                    styrke samarbeidet mellom virksomhetens innkjøpsfag,
                    næringsutvikling og fagrådgivere med store innkjøpsvolum, og
                    bidra til leverandørutvikling som viktig driver for det
                    lokale næringslivet.
                </p>

                <h3>Hvilken nytte har deltakelse i fellesskapet?</h3>
                <p>
                    Hensikten er at dere som pådriverteam skal få tilgang til
                    «best practice» prosessbistand, og oppdatert, fersk kunnskap
                    om innovative anskaffelser og om hvordan spre og øke bruken
                    av innovative anskaffelser i egen virksomhet.
                </p>
                <p>
                    Vi ser for oss at hvert pådriverteam består av mellom 2-5
                    personer, og at det gjerne kan variere hvem som deltar fra
                    samling til samling. Hovedpoenget er at kompetansen bygges
                    internt i teamet.
                </p>

                <h3>Hva er tidsplanen?</h3>
                <p>
                    Med bistand fra oss i LUP legges det opp til en prosess hvor
                    dere enes om hvem i organisasjonen som er motivert for, kan
                    ha nytte av, og ha en rolle som pådrivere for IOA internt i
                    virksomheten
                </p>
                <p>Første kull starter opp vinteren 2021/22.</p>

                <p>
                    <a href="https://forms.office.com/r/i3JaPEXrx6">
                        Trykk her for påmelding
                    </a>
                </p>

                <h3>Spørsmål?</h3>
                <p>
                    Spør din{' '}
                    <a href="https://innovativeanskaffelser.no/kontakt-oss/">
                        partnerkontakt
                    </a>
                    .
                </p>
            </div>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...state.user,
        ...state.global,
        ...ownProps,
    };
};

Ambassador.propTypes = {
    user: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(Ambassador);
