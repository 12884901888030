import React from 'react';
import * as PropTypes from 'prop-types';

const circle = (number, current) => (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="#EBEBEB">
        <circle
            cx={16}
            cy={16}
            r={16}
            fill={current ? '#7C17FF' : '#EBEBEB'}
            stroke="none"
            strokeWidth="1px"
        />
        <text
            fill={current ? '#ffffff' : '#7C17FF'}
            x={number > 9 ? '8.5' : '12'}
            y="21"
        >
            {number}
        </text>
    </svg>
);

const barSpan = (number, numberMax, setCurrentNumber) => {
    const rows = [];
    let madeRightDots = false;
    let madeLeftDots = false;

    for (let i = 1; i <= numberMax; i += 1) {
        if (i === 1 && numberMax > 3) {
            rows.push(
                <button
                    onClick={() =>
                        setCurrentNumber(number > 1 ? number - 1 : 1)
                    }
                    key={0}
                    className="circle"
                >
                    {circle('←', false)}
                </button>
            );
        }
        if (i < number + 3 && number > 3 && i !== 1 && !madeLeftDots) {
            madeLeftDots = true;
            rows.push(
                <button
                    onClick={() => setCurrentNumber(1)}
                    key="leftDots"
                    className="circle"
                >
                    {circle('...', false)}
                </button>
            );
        }
        if (i > number - 3 && i < number + 3) {
            rows.push(
                <button
                    onClick={() => setCurrentNumber(i)}
                    key={i}
                    className="circle"
                >
                    {circle(i, number === i)}
                </button>
            );
        }

        if (i === numberMax && numberMax >= number + 3 && !madeRightDots) {
            madeRightDots = true;
            rows.push(
                <button
                    onClick={() => setCurrentNumber(numberMax)}
                    key="rightDots"
                    className="circle"
                >
                    {circle('...', false)}
                </button>
            );
        }

        if (i === numberMax && numberMax > 3) {
            rows.push(
                <button
                    onClick={() =>
                        setCurrentNumber(
                            number >= numberMax ? numberMax : number + 1
                        )
                    }
                    key={i + 1}
                    className="circle"
                >
                    {circle('→', false)}
                </button>
            );
        }
    }
    return rows;
};

const MultiPageNavigator = ({
    currentNumber,
    maxNumbers,
    setCurrentNumber,
}) => {
    return (
        <div className="pagination">
            {barSpan(currentNumber, maxNumbers, setCurrentNumber)}
        </div>
    );
};

MultiPageNavigator.propTypes = {
    currentNumber: PropTypes.number.isRequired,
    maxNumbers: PropTypes.number.isRequired,
};

export default MultiPageNavigator;
