import React from 'react';
import * as PropTypes from 'prop-types';

import UserIcon from 'images/user-icon.svg';

const ContactCard = ({ name, title, email, phone, imageSrc, small }) => {
    return (
        <div className={`contact-card ${small ? 'small' : ''}`}>
            <div className="contact-card-image">
                {imageSrc && <img src={imageSrc} alt={name} />}
            </div>
            <div className="contact-card-info">
                <h3>Pådriver fra LUP</h3>
                <span>{name}</span>
                <span>
                    <a href={`mailto:${email}`}>{email}</a>
                </span>
                <span>{phone}</span>
            </div>
            <div className="contact-card-icon">
                <img src={UserIcon} alt="icon" />
            </div>
        </div>
    );
};

ContactCard.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    imageSrc: PropTypes.string,
};

ContactCard.defaultProps = {
    small: false,
};

export default ContactCard;
