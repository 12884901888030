import React from 'react';
import * as PropTypes from 'prop-types';

import CheckedIcon from 'images/checkbox-checked.svg';
import UnCheckedIcon from 'images/checkbox-unchecked.svg';

const Checkbox = (props) => {
    const { checked, onClick } = props;

    return (
        <div className="checkbox" onClick={onClick}>
            {checked && <img src={CheckedIcon} alt="Checked" />}
            {!checked && <img src={UnCheckedIcon} alt="Unchecked" />}
        </div>
    );
};

Checkbox.propTypes = {
    checked: PropTypes.bool,
    onClick: PropTypes.func,
};

Checkbox.defaultProps = {
    checked: false,
    onClick: undefined,
};

export default Checkbox;
