import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from 'reducers/rootReducer';

const persistConfig = {
    key: 'root',
    blacklist: [
        // Blacklisting to force redux to get filled per session.
    ],
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer);

export default store;
export const persistor = persistStore(store);
