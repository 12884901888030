/* eslint-disable */
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store';

import useCurrentUser from 'hooks/useCurrentUser';
import useGlobal from 'hooks/useGlobal';

import Header from 'components/Header';
import Footer from 'components/Footer';
import Menu from 'components/Menu';

import AmbassadorPage from 'pages/AmbassadorPage';
import CoursePage from 'pages/CoursePage';
import DataBankPage from 'pages/DataBankPage';
import DialogPage from 'pages/DialogPage';
import EditUserPage from 'pages/EditUserPage';
import ExamplesPage from 'pages/ExamplesPage';
import ForumEntryPage from 'pages/ForumEntryPage';
import FrontPageUser from 'pages/FrontPageUser';
import LandingPage from 'pages/LandingPage';
import PartnerPage from 'pages/PartnerPage';
import OtherActorsPage from 'pages/OtherActorsPage';
import SingleCoursePage from 'pages/SingleCoursePage';
import SinglePartnerPage from 'pages/SinglePartnerPage';
import ToolsPage from 'pages/ToolsPage';
import UrlLoginPage from 'pages/UrlLoginPage';

const App = () => {
    useGlobal();
    useCurrentUser();
    //useActors();
    const [menuState, setMenuState] = useState(false);
    const [tokenState, setTokenState] = useState(false);
    const [resetState, setResetState] = useState(false);
    const [greyBackground, setGreyBackground] = useState(true);

    if (tokenState && greyBackground) {
        setGreyBackground(false);
    }

    return (
        <Provider store={store}>
            <PersistGate persistor={persistor} loading={null}>
                <Router>
                    {/* <GlobalStyle /> */}
                    <Header
                        menuState={menuState}
                        setMenuState={setMenuState}
                        setTokenState={setTokenState}
                        setResetState={setResetState}
                        greyBackground={greyBackground}
                    />
                    <div className="spacer">
                        <div
                            className={`main-wrapper ${
                                greyBackground ? 'greyBackground' : ''
                            } ${!resetState && tokenState ? 'authToken' : ''}`}
                        >
                            <Menu
                                menuState={menuState}
                                setMenuState={setMenuState}
                            />
                            <div
                                className={`main-content ${
                                    greyBackground ? 'greyBackground' : ''
                                } ${
                                    !resetState && tokenState ? 'authToken' : ''
                                }`}
                            >
                                <PropsRoute
                                    exact
                                    path="/login"
                                    component={LandingPage}
                                    extras={{
                                        setGreyBackground,
                                        greyBackground,
                                        tokenState,
                                        setTokenState,
                                    }}
                                />
                                <AuthRoute
                                    exact
                                    path="/paadriver"
                                    component={AmbassadorPage}
                                />
                                <Route
                                    exact
                                    path="/url/:email/:hash"
                                    component={UrlLoginPage}
                                />
                                <Route
                                    exact
                                    path="/kurs/:slug"
                                    component={SingleCoursePage}
                                />
                                <PropsAuthRoute
                                    exact
                                    path="/aktor/:slug"
                                    component={SinglePartnerPage}
                                    extras={{
                                        setGreyBackground,
                                        greyBackground,
                                        tokenState,
                                        setTokenState,
                                    }}
                                />
                                <PropsRoute
                                    exact
                                    path={['/databank', '/database']}
                                    component={DataBankPage}
                                    extras={{
                                        setGreyBackground,
                                        greyBackground,
                                        tokenState,
                                        setTokenState,
                                    }}
                                />
                                <PropsRoute
                                    exact
                                    path="/verktoy"
                                    component={ToolsPage}
                                    extras={{
                                        setGreyBackground,
                                        greyBackground,
                                        tokenState,
                                        setTokenState,
                                    }}
                                />
                                <AuthRoute
                                    exact
                                    path="/maler"
                                    component={ExamplesPage}
                                />
                                <AuthRoute
                                    exact
                                    path="/"
                                    component={FrontPageUser}
                                />
                                <AuthRoute
                                    exact
                                    path="/profil"
                                    component={EditUserPage}
                                />
                                <AuthRoute
                                    exact
                                    path="/din-virksomhet"
                                    component={PartnerPage}
                                />

                                <AuthRoute
                                    exact
                                    path="/kurs"
                                    component={CoursePage}
                                />
                                <AuthRoute
                                    exact
                                    path="/forum"
                                    component={DialogPage}
                                />
                                <AuthRoute
                                    exact
                                    path="/forum/:slug"
                                    component={ForumEntryPage}
                                />
                                <AuthRoute
                                    exact
                                    path="/andre"
                                    component={OtherActorsPage}
                                />
                                <AuthRoute
                                    exact
                                    path="/aktor"
                                    component={OtherActorsPage}
                                />
                            </div>
                        </div>
                        <Footer
                            greyBackground={greyBackground}
                            className={!resetState && tokenState && 'authToken'}
                        />
                    </div>
                </Router>
            </PersistGate>
        </Provider>
    );
};

export default App;

const AuthRoute = ({ component: Component, ...rest }) => {
    const user = store.getState().user;
    const reset = user.user?.acf?.reset_password;
    const authToken = user?.authToken;

    return (
        <Route
            key={authToken}
            {...rest}
            render={(props) =>
                authToken && !reset ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={'/login'} />
                )
            }
        />
    );
};

const PropsAuthRoute = ({ component: Component, extras, ...rest }) => {
    return <AuthRoute exact component={Component} {...rest} {...extras} />;
};

const PropsRoute = ({ component: Component, extras, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => <Component {...props} {...extras} />}
        />
    );
};
