import React from 'react';
import * as PropTypes from 'prop-types';

const Bullet = (
    <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="4" cy="4" r="4" fill="#7C17FF" />
    </svg>
);

const CourseText = ({ data }) => {
    return (
        <>
            <div className="course-text">
                <div className="ingress" dangerouslySetInnerHTML={{ __html: data.intro }} />
                <div dangerouslySetInnerHTML={{ __html: data.content }} />
            </div>
        </>
    );
};

CourseText.propTypes = {
    data: PropTypes.object.isRequired,
};

export default CourseText;

