import React, { useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';

import arrow from 'images/svg/arrow.svg';
import cross from 'images/svg/cross.svg';

const defaultState = {
    uploadingExt: false,
    deleteUploadState: false,
    uploadingShortName: false,
    uploadingName: false,
};

const ForumUploadFile = ({
    file,
    editMode,
    setUploadArray,
    progress,
    removeFile,
}) => {
    const fileInput = useRef(null);
    const [
        { uploadingShortName, uploadingExt, deleteUploadState, uploadingName },
        setUploadingState,
    ] = useState(defaultState);
    // const time = timeStampToObject(file.timestamp);

    useEffect(() => {
        if (progress === 100) {
            setTimeout(() => {
                setUploadingState(defaultState);
            }, 2000);
        }
    }, [editMode, progress]);
    
    const progressStyles = {
        backgroundPosition: progress === undefined ? `-100% bottom` : `${0 - progress}% bottom`,
    }

    const uploadFile = (e, name, trigger = false) => {
        const uploadedFile = e.target.files[0];

        if (uploadedFile) {
            const extension = uploadedFile.name.substring(
                uploadedFile.name.lastIndexOf('.') + 1
            );
            const fileName = uploadedFile.name.substring(
                0,
                uploadedFile.name.length - extension.length
            );

            let shortName = fileName;

            if (fileName.length > 10) {
                shortName = `${fileName.substr(0, 10)}...`;
            }

            setUploadingState({
                deleteUploadState: false,
                uploadingExt: extension,
                uploadingShortName: shortName,
                uploadingName: uploadedFile.name,
            });

            setUploadArray({ name, uploadedFile });
        }
    };

    const triggerRemoveFile = () => {
        // setUploadArray({ name: file.name, uploadedFile: 'delete' });
        // this.setState({people: this.state.people.filter(item => item !== e.target.value);});

        removeFile(uploadingName);
        setUploadingState({
            uploadingExt: false,
            deleteUploadState: false,
            uploadingShortName: false,
        });
    };

    const docTypes =
        '.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';

    const fileUpload = (
        <div className="forum-upload-file-wrapper" style={progressStyles} progress="0" >
            <input
                type="file"
                accept={docTypes}
                ref={fileInput}
                onChange={(e) => uploadFile(e, file.name)}
            />
            <div className="file-name" onClick={() => fileInput.current.click()}>
                <span>Last opp {file.name.toLowerCase()}</span>
            </div>
            <div className="add-wrapper" onClick={() => fileInput.current.click()}>
                <img src={cross} alt="Legg til fil" />
            </div>
        </div>
    );

    const fileOverwrite = (
        <div className="forum-upload-file-wrapper animate" style={progressStyles} progress={progress}>
            <div className="file-name">
                {!uploadingExt && file.ext && (
                    <>
                        <span className="bold">{file.name}</span>
                        <span>({file.filename_short})</span>
                    </>
                )}
                {uploadingExt && (
                    <>
                        <span>({uploadingShortName})</span>
                    </>
                )}
            </div>
            <div className="remove-wrapper">
                <img
                    onClick={() => triggerRemoveFile(file.name)}
                    src={cross}
                    alt="Fjern fil"
                />
            </div>
        </div>
    );

    const fileDownload = (
        <div className="forum-upload-file-wrapper" style={progressStyles} progress={100}>
            <div className="file-name">
                <a href={file.url} download>
                    <span className="bold noMobile">{file.name}</span>
                    <span>({file.filename_short})</span>
                </a>
            </div>
            <div className="arrow-wrapper">
                <a href={file.url} download>
                    {' '}
                    <img src={arrow} alt="Last ned fil" />
                </a>
            </div>
        </div>
    );

    const fileTriggerEdit = (
        <div className="forum-upload-file-wrapper" style={progressStyles} progress="0">
            <input
                type="file"
                accept={docTypes}
                ref={fileInput}
                onChange={(e) => uploadFile(e, file.name, true)}
            />
            <div className="file-name bold"
                onClick={() => {
                    fileInput.current.click();
                }}
            >
                <span>Last opp {file.name.toLowerCase()}</span>
            </div>
            <div className="add-wrapper"
                onClick={() => {
                    fileInput.current.click();
                }}
            >
                <div>
                    <img src={cross} alt="Legg til fil" />
                </div>
            </div>
        </div>
    );
    if (!editMode) {
        return fileDownload;
    }
    if ((!file.ext && !uploadingExt) || deleteUploadState) {
        return fileUpload;
    }
    if (file.ext || uploadingExt) {
        return fileOverwrite;
    }
};

ForumUploadFile.defaultProps = {
    editable: false,
    className: '',
};

ForumUploadFile.propTypes = {
    className: PropTypes.string,

    editable: PropTypes.bool,
};

export default ForumUploadFile;

