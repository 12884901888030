import format from 'date-fns/format';
import { nb } from 'date-fns/locale';
import fromUnixTime from 'date-fns/fromUnixTime';

const formattedDate = (stamp, string) => {
    return format(fromUnixTime(stamp), string, { locale: nb });
};

const timeStampToObject = (stamp) => {
    if (!stamp) return false;

    return {
        day: formattedDate(stamp, 'd'),
        dayString: formattedDate(stamp, 'EEEE'),
        month: formattedDate(stamp, 'MM'),
        monthString: formattedDate(stamp, 'MMMM'),
        monthStringShort: formattedDate(stamp, 'LLL').split('.').join(''),
        year: formattedDate(stamp, 'yyyy'),
        hours: formattedDate(stamp, 'H:mm'),
    };
};

export default timeStampToObject;
