import React from 'react';

import * as PropTypes from 'prop-types';

const LoginHero = ({ email, subject, more }) => {
    return (
        <div className="login-hero-wrapper">
            <h2>Velkommen til partnerportalen.<br/>
                Dine verktøy for innovative anskaffelser, samlet på ett sted
            </h2>
            <p>
                Programmet tilbyr alle offentlige innkjøpere bistand med
                innovative anskaffelser. Som partner kan man i tillegg delta i
                erfaringsnettverket for innovative anskaffelser og får enkel
                tilgang til relevante verktøy i partnerportalen.
            </p>
            <div className="double-buttons">
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`mailto:${email}?subject=${subject}`}
                    className="btn primary"
                >
                    <span>Bli partner</span>
                </a>
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`mailto:${more}`}
                    className="btn"
                >
                    <span>Jeg vil vite mer</span>
                </a>
            </div>
        </div>
    );
};

LoginHero.propTypes = {
    email: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    more: PropTypes.string.isRequired,
};

export default LoginHero;
