import React, { useReducer } from 'react';
import { withRouter } from 'react-router-dom';

import ApiManager from 'ApiManager';

import Button from 'components/Button';

import UserActions from 'reducers/userReducer';

const Api = new ApiManager();

const LoginPage = () => {
    const [state, setState] = useReducer(
        (state, setState) => ({ ...state, ...setState }),
        {
            requesting: false,
            errorLogin: false,
            errorData: false,
            errorReset: false,
            goldenFish: false,
            resetComplete: false,
            fields: {
                username: '',
                password: '',
            },
        }
    );

    const onChangeUsername = (e) => {
        const { fields } = state;
        const newFields = { ...fields };
        newFields.username = e.target.value;
        setState({ fields: newFields });
    };

    const onChangePassword = (e) => {
        const { fields } = state;
        const newFields = { ...fields };
        newFields.password = e.target.value;
        setState({ fields: newFields, errorLogin: false });
    };

    const setGoldenFish = () => {
        setState({ goldenFish: true });
    };

    const attemptLogin = () => {
        // const self = this;

        const { fields, requesting } = state;
        const newFields = { ...fields };

        if (
            newFields.username === '' ||
            newFields.password === '' ||
            requesting === true
        )
            return null;

        setState({ requesting: true });

        Api.loginUser(newFields.username, newFields.password)
            .then((response) => {
                const authToken = response.data.token;
                Api.setAuthToken(authToken);

                Api.getLoggedInUser()
                    .then((userResponse) => {
                        UserActions.logInUser(authToken, userResponse.data);
                        // ActorActions.setActor(userResponse.data.actor);

                        // Redirect successfully!
                        // this.props.history.push('/');
                    })
                    .catch((error) => {
                        // console.log('ERROR [getLoggedInUser]', error);
                        setState({ errorData: true });
                    });
            })
            .catch((error) => {
                // console.log('ERROR [loginUser]', error);
                setState({ errorLogin: true });
            })
            .finally(() => {
                setState({ requesting: false });
            });

        return true;
    };

    const attemptReset = () => {
        // const self = this;

        const { fields, requesting } = state;
        const newFields = { ...fields };
        if (newFields.username === '' || requesting === true) return null;
        setState({ requesting: true });

        Api.resetPassword(newFields.username)
            .then((response) => {
                if (response.data.email) {
                    setState({
                        resetComplete: true,
                        emailError: false,
                        errorReset: false,
                    });
                } else {
                    setState({
                        resetComplete: true,
                        emailError: true,
                        errorReset: false,
                    });
                }
            })
            .catch((error) => {
                console.log('ERROR [reset]', error);
                setState({ errorReset: true });
            })
            .finally(() => {
                setState({ requesting: false });
            });

        return true;
    };

    const {
        errorLogin,
        errorData,
        errorReset,
        resetComplete,
        goldenFish,
        emailError,
    } = state;

    return (
        <>
            {!goldenFish && (
                <>
                    <section className="login-wrapper">
                        <h3>Logg inn</h3>

                        <div className="login-fields">
                            <span>E-post adresse</span>
                            <input
                                type="text"
                                onChange={onChangeUsername}
                                aria-label="E-post adresse"
                            />
                            <span>Passord</span>
                            <input
                                type="password"
                                onChange={onChangePassword}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        attemptLogin();
                                    }
                                }}
                                aria-label="Passord"
                            />
                        </div>
                    </section>
                    <div className="double-buttons">
                        <Button primary onClick={attemptLogin}>
                            <span>Logg inn</span>
                        </Button>
                        <Button onClick={setGoldenFish}>
                            <span>Glemt passord?</span>
                        </Button>
                    </div>

                    {errorLogin && (
                        <h3 className="login-error">
                            Feil brukernavn og/eller passord
                        </h3>
                    )}
                    {errorData && (
                        <h3 className="login-error">Noe gikk galt</h3>
                    )}
                </>
            )}
            {goldenFish && (
                <>
                    <section className="login-wrapper">
                        {!resetComplete && (
                            <>
                                <h3>Nullstill passord</h3>
                                <div className="login-fields">
                                    <span>E-postadresse</span>
                                    <input
                                        type="text"
                                        onChange={onChangeUsername}
                                        aria-label="E-post adresse"
                                    />
                                </div>
                                <div className="double-buttons">
                                    <Button primary onClick={attemptReset}>
                                        <span>Send</span>
                                    </Button>
                                </div>
                            </>
                        )}
                    </section>
                    {resetComplete && !emailError && (
                        <h3 className="login-error">
                            Du vil straks motta en epost for nullstilling av
                            passord
                        </h3>
                    )}
                    {resetComplete && emailError && (
                        <h3 className="login-error">
                            Beklager, men noe gikk galt under sending av epost,
                            prøv igjen senere.
                        </h3>
                    )}
                    {errorReset && (
                        <h3 className="login-error">Ukjent bruker</h3>
                    )}
                </>
            )}
        </>
    );
};

export default withRouter(LoginPage);
