import React, { useState } from 'react';
import * as PropTypes from 'prop-types';

import ApiManager from 'ApiManager';

import File from './SingleFile';

import Button from 'components/Button';

import UserActions from 'reducers/userReducer';

const makeFileList = (possibleFiles, files) => {
    const fileArray = [];
    const unactiveFileArray = [];

    possibleFiles.map((possible) => {
        let found = false;
        files
            .filter((val) => val.name === possible.name)
            .map((file) => {
                if (file.uploaded === true) {
                    fileArray.push({ ...file });
                    found = true;
                }
                return true;
            });
        if (!found) {
            unactiveFileArray.push({
                name: possible.name,
                uploaded: false,
            });
        }
        return true;
    });
    let otherCount = 0;
    files.map((file) => {
        if (file.uploaded === true && file.name.includes('Andre dokumenter')) {
            // eslint-disable-next-line no-plusplus
            otherCount++;
            fileArray.push({ ...file });
        }
        return true;
    });
    if (otherCount > 0) {
        unactiveFileArray.push({
            name: `Andre dokumenter ${otherCount + 1}`,
        });
    } else {
        unactiveFileArray.push({
            name: `Andre dokumenter`,
        });
    }
    return [...fileArray, ...unactiveFileArray];
};

const Files = ({ title, possibleFiles, files, editable }) => {
    const fileList = makeFileList(possibleFiles, files);
    const [uploadArray, setUploadArray] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [progressState, setProgressState] = useState(100);
    const [errorState, setErrorState] = useState(false);

    const startUpload = () => {
        setErrorState(false);
        saveFile(uploadArray);
    };

    const saveFile = (uploadedFileArray) => {
        const Api = new ApiManager();

        const config = {
            onUploadProgress(progressEvent) {
                setProgressState(
                    Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    )
                );
            },
        };

        Api.editCurrentActorFile(uploadedFileArray, config)
            .then(() => {
                Api.getLoggedInUser().then((userResponse) => {
                    UserActions.logInUser(false, userResponse.data);
                });
            })
            .catch((error) => {
                setErrorState('Noe gikk desverre galt ved opplasting');
            })
            .finally(() => {
                setUploadArray([]);
                setEditMode(false);
            });
    };

    return (
        <div className="files-list-wrapper">
            <div className="header">
                <h3>{title}</h3>
                {editable && (
                    <>
                        {!editMode && (
                            <button className="edit-files" onClick={() => setEditMode(true)}>
                                Rediger filer
                            </button>
                        )}
                        {editMode && (
                            <button className="edit-files" onClick={() => setEditMode(false)}>
                                Angre og lukk
                            </button>
                        )}
                    </>
                )}
            </div>
            {/* <Content> */}
                <div className="file-list">
                    {fileList.map((file) => {
                        return (
                            <File
                                key={file.name}
                                file={file}
                                editMode={editMode}
                                editable={editable}
                                setEditMode={setEditMode}
                                setUploadArray={(val) => {
                                    setUploadArray([
                                        ...uploadArray,
                                        { ...val },
                                    ]);
                                    setErrorState(false);
                                }}
                                directSave={(val) => saveFile([{ ...val }])}
                                progress={progressState}
                            />
                        );
                    })}
                </div>
                <div className="error">{errorState}</div>
            {/* </Content> */}
            {editMode && (
                <div className="double-buttons">
                    <Button primary onClick={() => startUpload()}>
                        <span>Lagre og lukk</span>
                    </Button>
                    <Button onClick={() => setEditMode(false)}>
                        <span>Angre og lukk</span>
                    </Button>
                </div>
            )}
        </div>
    );
};

Files.propTypes = {
    title: PropTypes.string,
    possibleFiles: PropTypes.array.isRequired,
    files: PropTypes.array.isRequired,
    editable: PropTypes.bool,
};

Files.defaultProps = {
    title: 'Filer',
    editable: false,
};

export default Files;

