import React from 'react';

const Divider = () => {
    return (
        <svg width={309} height={1} viewBox="0 0 309 1" fill="none">
            <path stroke="#000" strokeOpacity={0.18} d="M0 .5h309" />
        </svg>
    );
}

export default Divider;
