import React from 'react';

const SearchBar = ({ searchState, setSearchState }) => {
    return (
        <div className="search-bar">
            <h3>Søk etter innlegg</h3>
            <input
                onChange={(e) =>
                    setSearchState(e.target.value.toLowerCase())
                }
                type="text"
                placeholder="Eks. prosedyre"
            />
            {/* <LoginButton primary onClick={() => console.log('click!')}>
                Søk
            </LoginButton>
            */}
        </div>
    );
};

SearchBar.propTypes = {};

export default SearchBar;
