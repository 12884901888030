/* eslint-disable */
import store from 'store';

const defaultState = [];

const SET_ACTOR = 'SET_ACTOR';

export const ActorReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_ACTOR:
            return action.actor;
        default:
            return state;
    }
};

export default {
    setActor: (actorObject) => {
        store.dispatch({
            type: SET_ACTOR,
            actor: actorObject,
        });
    },
};
