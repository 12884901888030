import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import * as PropTypes from 'prop-types';

import GoBack from 'components/GoBack';

import hamburger from 'images/svg/hamburger';
import partnerLogo from 'images/svg/partnerLogo';

const Header = ({ authToken, setTokenState, user, setResetState, menuState, setMenuState, greyBackground }) => {

    useEffect(() => {
        // eslint-disable-next-line camelcase
        const reset = user?.acf ? user?.acf?.reset_password : true;
        setResetState(reset);
        setTokenState(authToken);
    }, [authToken, user]);

    const goBackSelector = (currentLocation, subLocation) => {
        if (currentLocation === 'login' || currentLocation === '')
            return <GoBack gone text="" />;
        if (subLocation === 'forum') return <GoBack text="Forum" />;
        if (subLocation === 'kurs')
            return <GoBack text="Kurs og inspirasjon" />;
        if (subLocation === 'aktor')
            return <GoBack text="Andre virksomheter" />;
        if (currentLocation === 'databank')
            return <GoBack text="Verktøy" to={'verktoy'} />;

        if (currentLocation !== '' && currentLocation !== 'login') {
            return <GoBack wayBack text="Forsiden" />;
        }
        return <div />;
    }

    const location = window.location.href.split('/');
    const currentLocation = location.pop();
    const subLocation = location.pop();

    return (
        <header
            className={`authToken ${greyBackground ? 'greyBackground' : ''}`}
            aria-label="header"
            role="banner"
        >
            <div className={`header-top ${!authToken ? 'middle' : ''}`}>
                {authToken && (
                    <div className="hamburger" onClick={() => setMenuState(!menuState)}>
                        {hamburger(menuState)}
                    </div>
                )}
                {authToken === 'disabled' && (
                    <div className="user-content">
                        <span className="user-name">Hei, {user.name}!</span>
                        <div className="user-image">
                            <Link to="/profil">
                                {user.image && (
                                    <img
                                        key={user.image_key}
                                        alt={user.name}
                                        src={user.image}
                                    />
                                )}
                            </Link>
                        </div>
                    </div>
                )}
                <>
                    <h3>Partnerportalen</h3>
                    {authToken && <div />}
                </>
            </div>
            <div className="header-top-desktop">
                <div className="go-back-wrapper">
                    {goBackSelector(currentLocation, subLocation)}
                </div>
                {/* <div className={authToken && 'float-left'}>condition ? value : undefined */}
                <div className={authToken ? 'float-left' : undefined}>
                    <div className="title-wrapper">
                        {partnerLogo()}
                        <Link to="/">
                            <h3>Partnerportalen</h3>
                        </Link>
                    </div>
                </div>
                {!!authToken && (
                    <div className="user-content">
                        <Link to="/profil">
                            <span>Innlogget som</span>{' '}
                            <span>{user.name}</span>
                        </Link>
                        <div className="user-image">
                            <Link to="/profil">
                                {user.image && (
                                    <img alt={user.name} src={user.image} />
                                )}
                            </Link>
                        </div>
                    </div>
                )}
                {!authToken && <div />}
            </div>
        </header>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...state.user,
        ...ownProps,
    };
};

Header.propTypes = {
    authToken: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    setTokenState: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    menuState: PropTypes.bool.isRequired,
    setMenuState: PropTypes.func.isRequired,
    setResetState: PropTypes.func.isRequired,
};

Header.defaultProps = {
    authToken: null,
};

export default connect(mapStateToProps)(withRouter(Header));
