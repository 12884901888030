import React from 'react';
import * as PropTypes from 'prop-types';
import slugify from 'slugify';

import AccordionItem from 'components/Accordion/AccordionItem';

const Accordion = (props) => {
    const { items, className } = props;

    return (
        <ul className={`accordion ${className}`}>
            {items.map((item) => {
                return <AccordionItem key={slugify(item.label)} item={item} />;
            })}
        </ul>
    );
};

export default Accordion;

Accordion.propTypes = {
    items: PropTypes.array.isRequired,
    className: PropTypes.string,
};

Accordion.defaultProps = {
    className: '',
};
