import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';

import ApiManager from 'ApiManager';

import Button from 'components/Button';

import ProfileImage from 'images/profile.png';

import AddMember from 'pages/PartnerPage/addMember';

const Api = new ApiManager();

const TeamMember = ({
    isAdmin,
    id,
    name,
    position,
    image,
    phone,
    email,
    userIsAdmin,
    members,
    setMembers,
}) => {
    const confirmDeleteUser = (id) => {
        if (confirm('Vil du slette brukeren?')) {
            Api.removeMember(id)
                .then((response) => {
                    let newMembers = members.filter(
                        (member) => id !== member.id
                    );
                    setMembers(newMembers);
                })
                .catch((error) => {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                });
        }
    };

    return (
        <div
            className={`team-member ${phone && email ? 'larger' : ''}`}
            tabIndex="0"
        >
            <div className="team-member-image">
                <img src={image} alt={name} />
            </div>
            <div className="team-member-text">
                <div className="name">{name}</div>
                {isAdmin && <span>-administrator-</span>}
                <div className="position">{position}</div>
                <a href={`mailto: ${email}`} className="email">
                    {email && email}
                </a>
                <div className="phone">{phone && phone}</div>
            </div>
            {userIsAdmin && (
                <button
                    className="team-member-delete"
                    onClick={() => confirmDeleteUser(id)}
                >
                    X
                </button>
            )}
        </div>
    );
};

TeamMember.propTypes = {
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool,
};

TeamMember.defaultProps = {
    isAdmin: false,
};

const Team = ({ isAdmin, members, administrators }) => {
    const [showForm, setShowForm] = useState(false);
    const [currentMembers, setCurrentMembers] = useState(members);

    const inviteMember = (e) => {
        e.stopPropagation();
        setShowForm(true);
    };
    return (
        <>
            <div className="team-wrapper">
                <div className="team-members">
                    {administrators?.map((user) => {
                        return (
                            <TeamMember
                                key={user.id}
                                name={user.name}
                                phone={user.phone}
                                email={user.email}
                                position={user.title || ''}
                                image={user.image ? user.image : ProfileImage}
                                isAdmin={true}
                            />
                        );
                    })}
                    {currentMembers?.map((user) => {
                        return (
                            <TeamMember
                                key={user.id}
                                id={user.id}
                                name={user.name}
                                phone={user.phone}
                                email={user.email}
                                position={user.title || ''}
                                image={user.image ? user.image : ProfileImage}
                                userIsAdmin={isAdmin}
                                members={currentMembers}
                                setMembers={setCurrentMembers}
                            />
                        );
                    })}
                </div>
                {isAdmin && (
                    <Button onClick={(e) => inviteMember(e)} primary>
                        Legg til brukere +
                    </Button>
                )}
            </div>
            {showForm && <AddMember setShowForm={setShowForm} />}
        </>
    );
};

Team.propTypes = {
    members: PropTypes.array.isRequired,
    administrators: PropTypes.array.isRequired,
    isAdmin: PropTypes.bool,
};

Team.defaultProps = {
    isAdmin: false,
};

export default Team;
