import React from 'react';

import arrow from 'images/svg/arrow.svg';

const SingleFile = (file) => {
    return (
        <div className="file-wrapper">
            <div className="name">
                <a href={file.url} download>
                   {file.filename_short}
                </a>
            </div>
            <div className="arrow">
                <a href={file.url} download>
                    {' '}
                    <img src={arrow} alt="Last ned fil" />
                </a>
            </div>
        </div>
    );
};

const CourseFiles = ({ files }) => {
    return (
        <div className="course-files">
            <h2>Filer</h2>
            <div className="list">
                {files.map((file) => {
                    return SingleFile(file);
                })}
            </div>
        </div>
    );
};

export default CourseFiles;
