import { Link, withRouter, useLocation } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

const Menu = ({ menuState, setMenuState, authToken, user }) => {
    const location = useLocation().pathname.split('/')[1];
    const resetActive = !user?.acf?.reset_password !== false;

    const menuClick = () => {
        setMenuState(false);
        window.scrollTo(0, 0);
    };

    return (
        <>
            {resetActive && authToken && (
                <nav className={menuState ? 'open' : undefined} aria-label="main" role="navigation">
                    <ul>
                        <li>
                            <Link
                                className={location === '' ? 'active' : ''}
                                onClick={() => menuClick()}
                                to="/"
                            >
                                Forside
                            </Link>
                        </li>
                        <li>
                            <Link
                                className={
                                    location === 'din-virksomhet'
                                        ? 'active'
                                        : ''
                                }
                                onClick={() => menuClick()}
                                to="/din-virksomhet"
                            >
                                Din virksomhet
                            </Link>
                        </li>
                        <li>
                            <Link
                                className={location === 'forum' ? 'active' : ''}
                                onClick={() => menuClick()}
                                to="/forum"
                            >
                                Forum
                            </Link>
                        </li>
                        <li>
                            <Link
                                className={
                                    location === 'databank' ||
                                    location === 'maler' ||
                                    location === 'verktoy'
                                        ? 'active'
                                        : ''
                                }
                                onClick={() => menuClick()}
                                to="/verktoy"
                            >
                                Verktøy
                            </Link>
                        </li>
                        <li>
                            <Link
                                className={location === 'kurs' ? 'active' : ''}
                                onClick={() => menuClick()}
                                to="/kurs"
                            >
                                Kurs og inspirasjon
                            </Link>
                        </li>
                        <li>
                            <Link
                                className={
                                    location === 'andre' || location === 'aktor'
                                        ? 'active'
                                        : ''
                                }
                                onClick={() => menuClick()}
                                to="/andre"
                            >
                                Andre virksomheter
                            </Link>
                        </li>
                    </ul>
                </nav>
            )}
        </>
    );
};

Menu.propTypes = {
    menuState: PropTypes.bool.isRequired,
    setMenuState: PropTypes.func.isRequired,
    authToken: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    user: PropTypes.object,
};

Menu.defaultProps = {
    authToken: null,
    user: null,
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...state.user,
        ...ownProps,
    };
};

export default connect(mapStateToProps)(withRouter(Menu));
