/* eslint-disable */
import store from 'store';

const defaultState = {
    global: {},
};

const SET_GLOBAL = 'SET_GLOBAL';

export const GlobalReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_GLOBAL:
            return {
                ...{},
                ...state,
                ...{
                    options: action.global.options,
                    partners: action.global.partners,
                    events: action.global.events,
                    static_events: action.global.static_events,
                    wpUrl: action.global.wp_url,
                },
            };
        default:
            return state;
    }
};

export default {
    setGlobal: (globalObject) => {
        store.dispatch({
            type: SET_GLOBAL,
            global: globalObject,
        });
    },
};
