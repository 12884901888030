import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import Accordion from 'components/Accordion';
import ContactCard from 'components/ContactCard';
import Checkbox from 'components/Checkbox';
import StepProgressBar from 'components/StepProgressBar';

import useActors from 'hooks/useActors';

import UserIcon from 'images/user-icon.svg';
import AnskaffelserIcon from 'images/anskaffelser.svg';
import DocumentIcon from 'images/document.svg';

import Procurements from 'pages/PartnerPage/procurements';
import Strategies from 'pages/PartnerPage/strategies';
import Team from 'pages/PartnerPage/team';

const getDocumentStatus = (type, documents) => {
    return documents
        .filter((val) => val.name === type)
        .map((document) => {
            return document.uploaded;
        })[0];
};

const SinglePartnerPage = (props) => {
    useActors();
    const {
        match,
        actors,
        setGreyBackground,
        greyBackground,
        tokenState,
    } = props;
    const { slug } = match.params;
    const uploadRef = useRef(null);

    useEffect(() => {
        if (greyBackground && !tokenState) {
            setGreyBackground(false);
        }
    }, [greyBackground, setGreyBackground, tokenState]);

    if (!actors) {
        return <div className="page-partner-wrapper"></div>;
    }
    if (!actors.length > 0) return <div className="page-partner-wrapper"></div>;

    const actor = actors.filter((actor) => actor.slug === slug)[0];

    if (!actor) return <div className="page-partner-wrapper"></div>;

    const { documents } = actor;

    const innovationSteps = [
        {
            icon: (
                <Checkbox
                    checked={
                        getDocumentStatus('Anskaffelsesstrategi', documents) ||
                        getDocumentStatus('Virksomhetsstrategi', documents)
                    }
                />
            ),
            label: 'Anskaffelsesstrategi',
            content: false,
        },
        {
            icon: <Checkbox checked={actor.innovation.innovation} />,
            label: 'Innovasjonsvennlig anskaffelse',
            content: false,
        },
        {
            icon: <Checkbox checked={actor.innovation.procurement} />,
            label: 'Anskaffelse av innovasjon',
            content: false,
        },
        {
            icon: <Checkbox checked={actor.members.length >= 1} />,
            label: 'Tverrfaglige team',
            content: false,
        },
        {
            icon: (
                <Checkbox
                    checked={getDocumentStatus('Kontraktstrategi', documents)}
                />
            ),
            label: 'Kontraktstrategi eller annen rutinebeskrivelse',
            content: false,
        },
        {
            icon: (
                <Checkbox
                    checked={getDocumentStatus('Målstyring', documents)}
                />
            ),
            label: 'Målstyring og opplæring for IOA',
            content: false,
        },
    ];
    const yourActorTools = [
        {
            icon: <img src={UserIcon} alt="Team" />,
            label: 'Team',
            content: (
                <Team
                    isAdmin={false}
                    administrators={actor.administrator}
                    members={actor.members}
                />
            ),
        },
        {
            icon: <img src={AnskaffelserIcon} alt="Anskaffelser" />,
            label: 'Anskaffelser',
            content: <Procurements procurements={actor.procurements} />,
        },
        {
            icon: <img src={DocumentIcon} alt="Strategier og planer" />,
            label: 'Strategier og planer',
            content: <Strategies editable={false} actor={actor} />,
        },
    ];

    return (
        <div className="page-partner-wrapper">
            <div className="partner-info">
                <div className="partner-branding">
                    {!actor.logo && <h1>{actor.title}</h1>}
                    {actor.logo && <img src={actor.logo} alt={actor.logo} />}
                </div>

                <ContactCard
                    small
                    name={actor.promoter.name}
                    title={actor.promoter.title}
                    email={actor.promoter.email}
                    phone={actor.promoter.phone}
                    imageSrc={actor.promoter.image}
                />
            </div>
            <div className="innovation-steps">
                <h2>Grad av innovasjonsvennlighet</h2>
                <StepProgressBar
                    currentNumber={actor.innovation_progress}
                    maxNumbers={innovationSteps.length}
                    large
                />
                <p>
                    Virksomhet har gjennomført {actor.innovation_progress}
                    {' av '}
                    {innovationSteps.length} tiltak for en innovasjonsvennlig
                    anskaffelsespraksis.
                </p>

                <Accordion items={innovationSteps} />
            </div>
            <div className="actor-tools">
                <h2>Virksomheten</h2>

                <Accordion items={yourActorTools} />

                <div className="actor-tools-desktop">
                    <div className="half-width">
                        <h3>Team</h3>
                        {actor.administrator.length > 0 && (
                            <Team
                                isAdmin={false}
                                administrators={actor.administrator}
                                members={actor.members}
                            />
                        )}
                    </div>

                    <div className="half-width">
                        <h3>Anskaffelser</h3>
                        <Procurements procurements={actor.procurements} />
                    </div>
                </div>
                {actor.documents.length > 0 && (
                    <div className="strategies-wrapper" ref={uploadRef}>
                        <h2>Strategier og planer</h2>

                        <Strategies editable actor={actor} />
                    </div>
                )}
            </div>
            <div className="contact-card-wrapper">
                <h2>Kontaktperson</h2>
                <ContactCard
                    name={actor.promoter.name}
                    title={actor.promoter.title}
                    email={actor.promoter.email}
                    phone={actor.promoter.phone}
                    imageSrc={actor.promoter.image}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        actors: state.actors,
        ...ownProps,
    };
};

export default connect(mapStateToProps)(SinglePartnerPage);
