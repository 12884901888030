import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import ContactCard from 'components/ContactCard';

import Tools from 'pages/FrontPageUser/tools';

const ToolsPage = (props) => {
    const {
        user,
        options,
        setGreyBackground,
        greyBackground,
        tokenState,
    } = props;

    useEffect(
        (props) => {
            if (greyBackground && !tokenState) {
                setGreyBackground(false);
            }
        },
        [greyBackground, setGreyBackground, tokenState]
    );

    return (
        <>
            <div className="page-tools-wrapper">
                <h1>Verktøy</h1>
                <Tools
                    email={options.suggest_tools_email}
                    subject={options.suggest_tools_subject}
                    tools={[...options.tools]}
                    altMobile
                />
                {user.actor.promoter && (
                    <div className="contact-card-wrapper">
                        <h2>Kontaktperson</h2>
                        <ContactCard
                            name={user.actor.promoter.name}
                            title={user.actor.promoter.title}
                            email={user.actor.promoter.email}
                            phone={user.actor.promoter.phone}
                            imageSrc={user.actor.promoter.image}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...state.user,
        ...state.global,
        ...ownProps,
    };
};

ToolsPage.propTypes = {
    user: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(ToolsPage);
