import { useEffect } from 'react';
import ApiManager from 'ApiManager';
import ActorActions from 'reducers/actorReducer';

const Api = new ApiManager();

const useActors = () =>
    useEffect(() => {
        Api.getActors()
            .then((response) => {
                ActorActions.setActor(response.data);
                // ActorActions.setActor(userResponse.data.actor);
                // Redirect successfully!
            })
            .catch((error) => {
                console.log('ERROR [getGlobals]', error);
            });
    }, []);

export default useActors;
