import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as PropTypes from 'prop-types';

import Button from 'components/Button';

import timeStampToObject from 'parsers/timeStampToObject';

const courseTags = (toggleFilter, filter, tags, limit) => {
    return (
        <div className="courses-tags">
            {!limit && (
                <Button
                    primary={Object.keys(filter).length > 0}
                    onClick={() => toggleFilter(false)}
                >
                    {'Alle kurs'}
                </Button>
            )}
            {tags.map((tag) => {
                return (
                    <Button
                        key={tag}
                        primary={!filter[tag]}
                        onClick={() => toggleFilter(tag)}
                    >
                        {tag}
                    </Button>
                );
            })}
        </div>
    );
};

const getTags = (events, limit) => {
    let tags = [];
    events.map((event) => {
        event.categories
            .filter((category) => {
                if (!limit) return true;
                return limit.includes(category.name);
            })
            .map((category) => {
                tags = new Set([...tags, category.name]);
            });
    });

    return [...tags];
};

const CoursesAll = (props) => {
    const [filter, setFilter] = useState([]);
    const { limit } = props;

    const events = props.static ? props.static_events : props.events;

    if (events.length <= 0) return null;
    const tags = getTags(events, limit);

    const toggleFilter = (tag) => {
        if (tag) {
            if (filter[tag]) {
                const tmp = filter;
                delete tmp[tag];
                setFilter({
                    ...tmp,
                });
            } else {
                setFilter({
                    //  ...filter,
                    [tag]: true,
                });
            }
        } else {
            setFilter([]);
        }
    };

    return (
        <div className="courses-all">
            {!limit &&
                (props.static ? (
                    <h2 role="main" aria-label="Faste kurs" tabIndex="0">
                        Faste kurs
                    </h2>
                ) : (
                    <h2 role="main" aria-label="Kurs" tabIndex="0">
                        Kurs
                    </h2>
                ))}
            {courseTags(toggleFilter, filter, tags, limit)}
            {tags &&
                tags
                    .filter((tag) => {
                        if (filter.length <= 0) return !limit;
                        if (Object.keys(filter).length <= 0) return !limit;

                        return filter[tag];
                    })
                    .map((tag) => {
                        return (
                            <div className="courses-all-list" key={tag}>
                                <h2 tabIndex="0">{tag}</h2>
                                {events
                                    .filter((event) => {
                                        if (filter.length <= 0) return true;
                                        if (Object.keys(filter).length <= 0)
                                            return true;

                                        return (
                                            event.categories.filter(
                                                (category) => {
                                                    return (
                                                        tag === category.name &&
                                                        !!filter[category.name]
                                                    );
                                                }
                                            ).length > 0
                                        );
                                    })
                                    .map((event) => {
                                        const date = timeStampToObject(
                                            event.start
                                        );
                                        return (
                                            <div
                                                className="course"
                                                key={event.id}
                                            >
                                                <h3>
                                                    <Link
                                                        to={`/kurs/${event.slug}`}
                                                    >
                                                        {event.title_short}
                                                    </Link>
                                                </h3>
                                                {!props.static && (
                                                    <div className="course-date">
                                                        <span>
                                                            {date.day}.{' '}
                                                        </span>
                                                        <span>
                                                            {date.monthString}
                                                        </span>
                                                    </div>
                                                )}
                                                <div>
                                                    {event.address_location && (
                                                        <div className="course-speaker">
                                                            {
                                                                event.address_location
                                                            }
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="course-ingress">
                                                    <span>
                                                        {event.intro_short}
                                                    </span>
                                                    <div />
                                                </div>

                                                <div className="course-event-tag">
                                                    <Link
                                                        to={`/kurs/${event.slug}`}
                                                    >
                                                        Les mer og meld deg på
                                                    </Link>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        );
                    })}
        </div>
    );
};

CoursesAll.propTypes = {
    events: PropTypes.array.isRequired,
    static_events: PropTypes.array.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...state.global,
        ...ownProps,
    };
};

export default connect(mapStateToProps)(CoursesAll);
