import React from 'react';
import { Link } from 'react-router-dom';

const scrollToTop = () => {
    window.scrollTo(0, 0);
};
const DialogLatest = ({ entries }) => {
    return (
        <div className="dialog-latest">
            <div className="top">
                <h2>Forum</h2>
                <h2>Siste innlegg</h2>
            </div>
            <ul className="dialogs">
                {entries &&
                    entries.length > 0 &&
                    entries.slice(0, 4).map((entry) => {
                        return (
                            <li className="dialog" key={entry.id}>
                                <Link
                                    onClick={() => scrollToTop()}
                                    to={`/forum/${entry.id}`}
                                >
                                    <div className="image">
                                        <div>
                                            <img
                                                src={entry.author.image}
                                                alt={entry.author.name}
                                            />
                                        </div>
                                    </div>
                                    <div className="text">
                                        <span>{entry.title}</span>
                                        <span>{entry.author.name}</span>
                                    </div>
                                </Link>
                            </li>
                        );
                    })}
            </ul>
            <div className="bottom">
                <Link to="/forum" className="btn">
                    Bli med i samtalen
                </Link>
            </div>
        </div>
    );
};

export default DialogLatest;
