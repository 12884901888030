import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import Button from 'components/Button';
import Login from 'components/Login';
import PartnerLinks from 'components/PartnerLinks';

import partnerLogo from 'images/svg/partnerLogo';
import Partnerportal from 'images/Partnerportal.png';

import LoginHero from 'pages/LandingPage/loginHero';
import ResetPassword from 'pages/LandingPage/resetPassword';

const LandingPage = (props) => {
    const {
        authToken,
        user,
        options,
        partners,
        setTokenState,
        greyBackground,
        setGreyBackground,
        left
    } = props;
    const [loginState, setLoginState] = useState(false);

    // eslint-disable-next-line camelcase
    const reset = user?.acf ? user?.acf?.reset_password : true;

    useEffect(() => {
        if (!greyBackground) {
            setGreyBackground(true);
        }
    }, [greyBackground, setGreyBackground]);

    if (authToken && !reset) {
        if (!user) {
            //setTokenState(false);
        } else {
            if (user.name) {
                setTimeout(() => (window.location = '/'), 250);
            }
        }
    }
    if (authToken && reset) {
        setTokenState(false);
    }

    return (
        <div className="page-landing-wrapper">
            {(!authToken || reset) && 
                <div className="grey-background"></div>
            }

            <div className="content-wrapper">
                <div className="inner-content-wrapper">
                    {authToken && reset && <ResetPassword />}
                    {authToken && !reset && (
                        <div className="welcome">
                            <div className="grey-overlay"></div>
                        </div>
                    )}
                    {!authToken && !loginState && (
                        <>
                            <Button
                                onClick={() => setLoginState(true)}
                            >
                                {partnerLogo()}
                                <span>Logg inn</span>
                            </Button>
                            {options && (
                                <LoginHero
                                    email={options.signup_email}
                                    subject={options.signup_subject}
                                    more={options.tell_me_more_url}
                                />
                            )}
                        </>
                    )}
                    {!authToken && loginState && (
                        <>
                            <Login />
                        </>
                    )}
                </div>
                <div className="image-wrapper">
                    <img alt="art" src={Partnerportal} />
                    <img alt="art" src={Partnerportal} />
                </div>
            </div>
            <PartnerLinks partners={partners} />
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...state.user,
        ...state.global,
        ...ownProps,
    };
};

LandingPage.propTypes = {
    authToken: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    user: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
    partners: PropTypes.array.isRequired,
    setTokenState: PropTypes.func.isRequired,
    greyBackground: PropTypes.bool.isRequired,
    setGreyBackground: PropTypes.func.isRequired,
};

LandingPage.defaultProps = {
    authToken: null,
};

export default connect(mapStateToProps)(LandingPage);
