import React from 'react';
import * as PropTypes from 'prop-types';

import timeStampToObject from 'parsers/timeStampToObject';

const CourseInfo = ({ data }) => {
    const startTime = timeStampToObject(data.start);
    const endTime = timeStampToObject(data.end);

    return (
        <>
            <div className="course-info">
                <h1>{data.title}</h1>

                <div className="date">
                    <span>{startTime.day}. </span>
                    <span>{startTime.monthStringShort}</span>
                </div>
                <div className="time">
                    <div>
                        {data.address_location && (
                            <span>{data.address_location},</span>
                        )}
                        <span>
                            {startTime.day}. {startTime.monthString}{' '}
                            {startTime.year}
                        </span>
                    </div>
                </div>

                <div className="where">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={data.address_link}
                    >
                        {data.address}
                    </a>
                </div>
            </div>
        </>
    );
};

CourseInfo.propTypes = {
    data: PropTypes.object.isRequired,
};

export default CourseInfo;
