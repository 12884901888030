import { useEffect } from 'react';
import ApiManager from 'ApiManager';
import UserActions from 'reducers/userReducer';

const Api = new ApiManager();

const useCurrentUser = () =>
    useEffect(() => {
        Api.getLoggedInUser()
            .then((userResponse) => {
                UserActions.logInUser(false, userResponse.data);
                // ActorActions.setActor(userResponse.data.actor);
                // Redirect successfully!
            })
            .catch((error) => {
                console.log('ERROR [getLoggedInUser]', error);
                Api.setAuthToken(null);
                UserActions.logOutUser();
            });
    }, []);

export default useCurrentUser;
