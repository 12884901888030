import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ApiManager from 'ApiManager';

import Button from 'components/Button';
import GoBack from 'components/GoBack';

import useForum from 'hooks/useForum';
import useWindowSize from 'hooks/useWindowSize';

import dialogEntryCard from 'pages/DialogPage/dialogEntryCard';
import ForumUpload from 'pages/ForumEntryPage/forumUpload';

import timeStampToObject from 'parsers/timeStampToObject';

import ForumActions from 'reducers/forumReducer';

const ForumEntryPage = (props) => {
    useForum();
    const Api = new ApiManager();
    const size = useWindowSize();

    const { authToken, match, user, categories, entries } = props;
    const [attemptState, setAttemptState] = useState(false);
    const [progressState, setProgressState] = useState(0);
    const [errorState, setErrorState] = useState(false);
    const [uploadArray, setUploadArray] = useState([]);
    const [redirectState, setRedirectState] = useState(false);
    const [entryData, setEntryData] = useState({});

    const [categoryTags, setCategoryTags] = useState([]);
    const { slug } = match.params;

    const newEntry = slug === 'innlegg';

    useEffect(() => {
        setUploadArray([]);
        setProgressState(0);
        setRedirectState(false);
        setEntryData({});
    }, [entries]);

    const toggleCategory = (tag) => {
        if (tag) {
            if (categoryTags[tag]) {
                const tmp = categoryTags;
                delete tmp[tag];
                setCategoryTags({
                    ...tmp,
                });
            } else {
                setCategoryTags({
                    ...categoryTags,
                    [tag]: true,
                });
            }
        } else {
            setCategoryTags([]);
        }
    };

    const saveFile = (entryId, parent = false) => {
        const config = {
            onUploadProgress(progressEvent) {
                setProgressState(
                    Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    )
                );
            },
        };

        Api.uploadForumFile(uploadArray, entryId, parent, config)
            .then(() => {})
            .catch((error) => {
                setErrorState('Noe gikk desverre galt ved opplasting');
            })
            .finally(() => {
                setRedirectState(entryId);
            });
    };

    const submit = () => {
        if (attemptState) return;
        setAttemptState(true);

        Api.newForumEntry(entryData, categoryTags)
            .then((response) => {
                // console.log('response', response.data);
                saveFile(response.data);
            })
            .finally(() => {
                setAttemptState(false);
            });
    };

    const submitAnswer = () => {
        if (attemptState) return;
        setAttemptState(true);

        Api.replyForumEntry(entryData, slug)
            .then((response) => {
                saveFile(response.data, slug);
            })
            .finally(() => {
                setAttemptState(false);
            });
    };

    const categoriesTags = () => {
        return (
            <div className="tags">
                {categories.map((cat) => {
                    return (
                        <Button
                            key={cat.id}
                            primary={!!categoryTags[cat.id]}
                            onClick={() => toggleCategory(cat.id)}
                        >
                            {cat.name}
                        </Button>
                    );
                })}
            </div>
        );
    };

    const updateEntryData = (data) => {
        setEntryData({ ...entryData, ...data });
    };

    if (redirectState && progressState === 100 && slug !== 'innlegg') {
        setTimeout(
            () => {
                Api.getForum()
                    .then((forumResponse) => {
                        ForumActions.setGlobal(forumResponse.data);
                        // ActorActions.setActor(userResponse.data.actor);
                        // Redirect successfully!
                    })
                    .catch((error) => {
                        console.log('ERROR [getGlobals]', error);
                    });
            },
            uploadArray.length > 0 ? 2000 : 0
        );
    }

    if (redirectState && progressState === 100 && slug === 'innlegg') {
        setTimeout(
            () => {
                window.location.href = `/forum/${redirectState}`;
            },
            uploadArray.length > 0 ? 2000 : 0
        );
    }
    if (attemptState) return <div className="page-forum-entry-wrapper"></div>;
    if (newEntry)
        return (
            <div className="page-forum-entry-wrapper">
                <div className="banner">
                    <h1>Nytt innlegg</h1>
                    <Button primary onClick={() => submit()}>
                        <span>Publiser</span>
                    </Button>
                </div>
                <div className="edit-wrapper">
                    <div className="edit-fields">
                        <h2>Tittel</h2>
                        <input
                            type="text"
                            onChange={(e) =>
                                updateEntryData({ title: e.target.value })
                            }
                            value={entryData.title || ''}
                        />
                        <h2>Innhold</h2>
                        <textarea
                            type="textarea"
                            onChange={(e) =>
                                updateEntryData({ content: e.target.value })
                            }
                            value={entryData.content || ''}
                        />

                        <h2>Vedlegg</h2>
                        <ForumUpload
                            editable
                            progressState={progressState}
                            saveFile={saveFile}
                            uploadArray={uploadArray}
                            setUploadArray={setUploadArray}
                        />

                        <h2>Kategori</h2>
                        <div>{categoriesTags()}</div>
                    </div>
                    <div className="double-buttons">
                        <Button primary onClick={() => submit()}>
                            Publiser innlegg
                        </Button>
                    </div>
                </div>
            </div>
        );

    if (!entries) return <div className="page-forum-entry-wrapper"></div>;
    if (!entries.length > 0) return <div className="page-forum-entry-wrapper"></div>;

    const entry = entries.filter((item) => item.id.toString() === slug)[0];

    if (!entry) return <div className="page-forum-entry-wrapper"></div>;

    const date = timeStampToObject(entry.date);
    if (!newEntry)
        return (
            <div className="page-forum-entry-wrapper">
                <GoBack text="Til forum" />
                <div className="open-banner">
                    <h1>{entry.title}</h1>
                    <div>
                        Postet {date.day}. {date.monthString} klokken{' '}
                        {date.hours}{' '}
                    </div>
                </div>
                {dialogEntryCard(entry, size, false, true)}
                {entry.replies.length > 0 && (
                    <h3>Innlegget har fått {entry.replies.length} svar </h3>
                )}
                {entry.replies.map((reply, key) =>
                    dialogEntryCard(
                        reply,
                        size,
                        true,
                        true,
                        key < entry.replies.length - 1
                    )
                )}

                <div className="edit-wrapper">
                    <div className="banner">
                        <h1>Svar på innlegg</h1>
                        <Button primary onClick={() => submitAnswer()}>
                            Publiser
                        </Button>
                    </div>
                    <div className="edit-fields">
                        <span>Innhold</span>
                        <textarea
                            type="textarea"
                            onChange={(e) =>
                                updateEntryData({ content: e.target.value })
                            }
                            value={entryData.content || ''}
                        />

                        <h2>Vedlegg</h2>
                        <ForumUpload
                            editable
                            progressState={progressState}
                            saveFile={saveFile}
                            uploadArray={uploadArray}
                            setUploadArray={setUploadArray}
                            parent={slug}
                        />
                        <div className="double-buttons">
                            <Button
                                primary
                                onClick={() => submitAnswer()}
                            >
                                <span>Publiser svar</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...{},
        ...state.forum,
        ...ownProps,
    };
};

export default connect(mapStateToProps)(ForumEntryPage);
